import { NgIf } from '@angular/common';
import { Directive, HostBinding, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { BundesmasterUserRoleName, toHighestPowerLevel } from '@nx-bundesliga/bundesmaster/shared';
import { Subject, takeUntil } from 'rxjs';
import { UserAuthenticationService } from '../service/user-authentication.service';

interface BundesmasterAuthorizerDirectiveInput {
	/**
	 *
	 */
	role: BundesmasterUserRoleName | BundesmasterUserRoleName[];

	/**
	 *
	 */
	languages?: string[];

	/**
	 *
	 */
	languageOverride?: {
		language: string;
		role: BundesmasterUserRoleName;
	};
}

/**
 *
 */
@Directive({
	selector: '[bundesmasterAuthorizer]',
	hostDirectives: [
		{
			directive: NgIf,
			inputs: ['ngIfElse: bundesmasterAuthorizerElse']
		}
	],
	standalone: true
})
export class BundesmasterAuthorizerDirective implements OnInit, OnDestroy {
	@Input() bundesmasterAuthorizer?: BundesmasterUserRoleName | BundesmasterAuthorizerDirectiveInput | '';

	readonly #ngIfDirective = inject(NgIf);
	readonly #userService = inject(UserAuthenticationService);
	readonly #destroying$ = new Subject<void>();

	ngOnDestroy(): void {
		this.#destroying$.next();
		this.#destroying$.complete();
	}

	ngOnInit() {
		this.#userService.user$.pipe(takeUntil(this.#destroying$)).subscribe((user) => {
			let decision = false;

			if (user) {
				if (typeof this.bundesmasterAuthorizer === 'object' || typeof this.bundesmasterAuthorizer === 'string') {
					// otherwise check if any of the supplied rules are in the user roles
					const roles = typeof this.bundesmasterAuthorizer === 'string' ? [this.bundesmasterAuthorizer] : typeof this.bundesmasterAuthorizer.role === 'string' ? [this.bundesmasterAuthorizer.role] : this.bundesmasterAuthorizer.role;

					const requiredPowerLevel = toHighestPowerLevel(roles);
					decision = user.role.powerLevel >= requiredPowerLevel;

					if (typeof this.bundesmasterAuthorizer === 'object') {
						// check for language specific roles if neccessary
						if (this.bundesmasterAuthorizer.languages) {
							const languageRoles = this.bundesmasterAuthorizer.languages ?? [];
							decision = decision && languageRoles.some((l) => user.allowedLanguages.some((language) => language.isLanguage(l)));
						}

						// a specific language might reuqire a lower power level than all other resources
						const override = this.bundesmasterAuthorizer.languageOverride;
						if (override && user.allowedLanguages.some((language) => language.isLanguage(override.language))) {
							const neededPowerLevel = toHighestPowerLevel([override.role]);
							decision = user.role.powerLevel >= neededPowerLevel;
						}
					}
				} else {
					decision = !!user.id;
				}
			}

			this.#ngIfDirective.ngIf = decision;
		});
	}
}

/**
 *
 */
@Directive({
	selector: '[bundesmasterIsLoggedIn]',
	hostDirectives: [
		{
			directive: NgIf,
			inputs: ['ngIfElse: bundesmasterIsLoggedInElse']
		}
	],
	standalone: true
})
export class BundesmasterIsLoggedInDirective {}

/**
 *
 */
@Directive({
	selector: '[bundesmasterAuthorizerLanguageDisabled]',
	standalone: true
})
export class BundesmasterLanguageAuthorizerDisabledDirective {
	@HostBinding('disabled') disabled = false;
	@Input() set bundesmasterLanguageAuthorizerDisabled(data: string) {
		this.bundesmasterLanguageAuthorizerDisabled = data;
	}

	private userService = inject(UserAuthenticationService);
}
