<mat-sidenav-container class="sidenav-container">
	<mat-sidenav #drawer class="sidenav" [ngClass]="{'stage-dev': stage === 'development', 'stage-int': stage === 'integration'}" [fixedTopGap]="0" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
		<div class="logo">
			<img src="/assets/bundesmaster/bundesmaster_white_none.png" width="200" alt="BUNDESMASTER" />
		</div>

		<ng-container *bundesmasterAuthorizer="''; else login">
			<div class="state">
				<ul class="competition">
					<li [style.order]="getCompetitionDisplayOrder(c.value)" *ngFor="let c of ((competition$ | async)?.available | keyvalue)" [class.active]="c.key === (competition$ | async)?.active" (click)="setCompetition(c.key)" matRipple>{{ c.value.displayName }} </li>
				</ul>

				<ul class="language">
					<li *ngFor="let language of (activeLanguages$ | async)" [class.active]="language.active" (click)="setLanguage(language.code)" matRipple>{{ language.code }} </li>
				</ul>
			</div>

			<ul class="navigation">
				<li>
					<a routerLink="/login" routerLinkActive="active" matRipple>
						<mat-icon>dashboard</mat-icon>
						<span class="label">Dashboard</span>
					</a>
				</li>

				<li *bundesmasterAuthorizer="'bundesmaster.editor'">
					<a routerLink="/home" routerLinkActive="active" matRipple>
						<mat-icon>home</mat-icon>
						<span class="label">Homepage</span>
					</a>
				</li>

				<li *bundesmasterAuthorizer="'bundesmaster.contributor'">
					<a routerLink="/article/new" routerLinkActive="active" matRipple>
						<mat-icon>edit_document</mat-icon>
						<span class="label">Create Article</span>
					</a>
				</li>

				<li *bundesmasterAuthorizer="'bundesmaster.guest'">
					<a routerLink="/articles" #articleRouterLink="routerLinkActive" routerLinkActive="active" matRipple>
						<mat-icon>description</mat-icon>
						<span class="label">Articles</span>
					</a>

					<div class="submenu" *ngIf="articleRouterLink.isActive">
						<ng-container *ngFor="let editorialType of editorialTypes">
							<div class="editorialType" [ngClass]="{active: rla.isActive}" matRipple>
								<a class="list" [routerLink]="'/articles/' + editorialType" routerLinkActive="active" #rla="routerLinkActive" matRipple>{{ editorialType }}</a>
								<a class="new" [routerLink]="'/article/new/' + editorialType">
									<mat-icon>note_add</mat-icon>
								</a>
							</div>
							<div class="submenu" *ngIf="rla.isActive">
								<a [routerLink]="'/articles/' + editorialType + '/bundesliga'" routerLinkActive="active" matRipple>Bl1</a>
								<a [routerLink]="'/articles/' + editorialType + '/bundesliga-2'" routerLinkActive="active" matRipple>BL2</a>
								<a [routerLink]="'/articles/' + editorialType + '/my'" routerLinkActive="active" matRipple>My</a>
								<a [routerLink]="'/articles/' + editorialType + '/unpublished'" routerLinkActive="active" matRipple>Unpublished</a>
								<a [routerLink]="'/articles/' + editorialType + '/pending_approval'" routerLinkActive="active" matRipple>Needs Review</a>
								<a [routerLink]="'/articles/' + editorialType + '/approved'" routerLinkActive="active" matRipple>Approved</a>
								<a [routerLink]="'/articles/' + editorialType + '/scheduled'" routerLinkActive="active" matRipple>Scheduled</a>
								<hr />
							</div>
						</ng-container>

						<a routerLink="/articles/my" routerLinkActive="active" class="editorialType" matRipple>My Articles</a>
						<a routerLink="/articles/pending_approval" routerLinkActive="active" class="editorialType" matRipple>Needs Review</a>
						<a routerLink="/articles/approved" routerLinkActive="active" class="editorialType" matRipple>Approved</a>
						<a routerLink="/articles/scheduled" routerLinkActive="active" class="editorialType" matRipple>Scheduled</a>
					</div>
				</li>

				<li *bundesmasterAuthorizer="'bundesmaster.editor'">
					<a routerLink="/matchreport" routerLinkActive="active" matRipple>
						<mat-icon>description</mat-icon>
						<span class="label">AI MatchReports</span>
					</a>
				</li>

				<li *bundesmasterAuthorizer="'bundesmaster.guest'">
					<a routerLink="/story" #storyRouterLink="routerLinkActive" routerLinkActive="active" matRipple>
						<mat-icon>web_stories</mat-icon>
						<span class="label">Stories</span>
					</a>

					<div class="submenu" *ngIf="storyRouterLink.isActive">
						<ng-container *ngFor="let storyType of storyTypes">
							<div class="editorialType" [ngClass]="{active: rla.isActive}" matRipple>
								<a class="d-none" [routerLink]="'/story/' + storyType" routerLinkActive="active" #rla="routerLinkActive"></a>
								<a class="list" [routerLink]="'/story/' + storyType + '/list'" routerLinkActive="active" matRipple>{{ storyType }}</a>
								<a class="new" [routerLink]="'/story/' + storyType + '/new'" *bundesmasterAuthorizer="'bundesmaster.contributor'">
									<mat-icon>note_add</mat-icon>
								</a>
							</div>
							<div class="submenu" *ngIf="rla.isActive">
								<ng-container *ngIf="storyType === 'article_story'">
									<a [routerLink]="'/story/' + storyType + '/generated'" routerLinkActive="active" matRipple *bundesmasterAuthorizer="'bundesmaster.contributor'">AI Generation Jobs</a>
								</ng-container>
								<!--<a [routerLink]="'/story/' + storyType + '/my'" routerLinkActive="active" matRipple>My</a>
                                <a [routerLink]="'/story/' + storyType + '/unpublished'" routerLinkActive="active" matRipple>Unpublished</a>
                                <a [routerLink]="'/story/' + storyType + '/pending_approval'" routerLinkActive="active" matRipple>Needs Review</a>
                                <a [routerLink]="'/story/' + storyType + '/approved'" routerLinkActive="active" matRipple>Approved</a>
                                <a [routerLink]="'/story/' + storyType + '/scheduled'" routerLinkActive="active" matRipple>Scheduled</a>-->
								<hr />
							</div>
						</ng-container>
					</div>
				</li>

				<li *bundesmasterAuthorizer="'bundesmaster.viewer'">
					<a routerLink="/media" #mediaRouterLink="routerLinkActive" routerLinkActive="active" matRipple>
						<mat-icon>collections_outlined</mat-icon>
						<span class="label">Media Library</span>
					</a>

					<div *bundesmasterAuthorizer="'bundesmaster.contributor'" class="submenu">
						<a *ngIf="mediaRouterLink.isActive" routerLink="/media/upload" routerLinkActive="active" matRipple data-test-id="mediaUpload">Upload</a>
					</div>
				</li>

				<li *bundesmasterAuthorizer="'bundesmaster.viewer'">
					<a routerLink="/videos" #videoRouterLink="routerLinkActive" routerLinkActive="active" matRipple>
						<mat-icon>movie</mat-icon>
						<span class="label">Videos Library</span>
					</a>

					<div *bundesmasterAuthorizer="'bundesmaster.editor'" class="submenu">
						<a *ngIf="videoRouterLink.isActive" routerLink="/videos/upload" routerLinkActive="active" matRipple data-test-id="videosUpload">Upload</a>
					</div>
				</li>

				<li *bundesmasterAuthorizer="'bundesmaster.admin'">
					<a routerLink="/barfinder" #barfinderRouterLink="routerLinkActive" routerLinkActive="active" matRipple>
						<mat-icon>liquor</mat-icon>
						<span class="label">Barfinder</span>
					</a>

					<div class="submenu" *ngIf="barfinderRouterLink.isActive">
						<a routerLink="/barfinder/cities/list" routerLinkActive="active" matRipple>Cities</a>
					</div>
				</li>

				<li *bundesmasterAuthorizer="'bundesmaster.editor'">
					<a routerLink="/app-breaking-news" #breakingNewsRouterLink="routerLinkActive" routerLinkActive="active" matRipple data-test-id="app-breaking-news-default">
						<mat-icon>event</mat-icon>
						<span class="label">App Events</span>
					</a>
				</li>

				<li *bundesmasterAuthorizer="'bundesmaster.editor'">
					<a routerLink="/player-center" #playerCenterRouterLink="routerLinkActive" routerLinkActive="active" matRipple data-test-id="player-center-default">
						<mat-icon>groups</mat-icon>
						<span class="label">Players</span>
					</a>

					<div *ngIf="playerCenterRouterLink.isActive" class="submenu">
						<a routerLink="/player-center/upload" routerLinkActive="active" matRipple data-test-id="player-center-upload">Upload Player Images</a>
					</div>
				</li>

				<li *bundesmasterAuthorizer="'bundesmaster.editor'">
					<a routerLink="/clubs" [queryParams]="{ season: clubsPageLinkDefaultSeason }" routerLinkActive="active" matRipple>
						<mat-icon>sports_soccer</mat-icon>
						<span class="label">Clubs</span>
					</a>
				</li>

				<li *bundesmasterAuthorizer="'bundesmaster.editor'">
					<a routerLink="/broadcasters" #broadcasterRouterLink="routerLinkActive" routerLinkActive="active" matRipple>
						<mat-icon>cell_tower</mat-icon>
						<span class="label">Broadcasters</span>
					</a>

					<div class="submenu" *ngIf="broadcasterRouterLink.isActive">
						<a routerLink="/broadcasters/additions" routerLinkActive="active" matRipple>Broadcast Additions</a>
					</div>
				</li>

				<li class="spacer"></li>

				<li *bundesmasterAuthorizer="'bundesmaster.admin'">
					<a routerLink="/hall-of-fame" routerLinkActive="active" matRipple>
						<mat-icon>emoji_events</mat-icon>
						<span class="label">Hall of Fame</span>
					</a>
				</li>

				<li class="divider"></li>

				<li class="infos">
					<div class="debug">
						{{ stage }}
						<span class="value">v{{ buildVars.version }}-{{ buildVars.branch }}-{{ buildVars.build }}</span>
						/
						<span class="value">{{ buildVars.angularVersion }}</span>
						/
						<span class="value">{{ buildVars.nxVersion }}</span>

						(#{{ buildVars.commitId }})
						<div>{{ buildVars.commitTime }}</div>
					</div>
					<button mat-button (click)="copyDebugInfo()">copy debug information</button>
				</li>
			</ul>
		</ng-container>

		<ng-template #login>
			<div class="state logged-out">
				Nothing to see 🧙🏻‍♂️
				<button (click)="startLogin()" class="w-100" mat-flat-button color="primary">Login</button>
			</div>
		</ng-template>
	</mat-sidenav>

	<mat-sidenav-content>
		<mat-toolbar>
			<button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
				<mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
			</button>

			<span class="spacer"></span>

			<bundesmaster-authorizer-user-info [showName]="(isHandset$ | async) === false"></bundesmaster-authorizer-user-info>
		</mat-toolbar>

		<div class="content">
			<router-outlet></router-outlet>
		</div>
	</mat-sidenav-content>
</mat-sidenav-container>
