@if (enabledLinking === true && language && competition && playerName && playerName !== '') {
	<a [routerLink]="['/', language, competition, 'route-player', playerName | slugify] | translateRoute">
		<ng-container *ngTemplateOutlet="playerImageTmpl"></ng-container>
	</a>
}
@if (enabledLinking !== true) {
	<ng-container *ngTemplateOutlet="playerImageTmpl"></ng-container>
}

<ng-template #playerImageTmpl>
	@if (isLoading) {
		<img [ngSrc]="this.invertPlaceholder ? this.placeHolderImgInverted : this.placeHolderImg" [alt]="playerName" fill />
	} @else {
		@if (src && dimension && dimension > 0 && fetchPriority === 'high') {
			<img [ngSrc]="src | imageService: dimension : dimension" [alt]="playerName" [width]="dimension" [height]="dimension" priority (error)="handleMissingImage($event)" />
		}
		@if (src && dimension && dimension > 0 && fetchPriority !== 'high') {
			<img [ngSrc]="src | imageService: dimension : dimension" [alt]="playerName" [width]="dimension" [height]="dimension" (error)="handleMissingImage($event)" />
		}
		@if (src && !(dimension && dimension > 0)) {
			<img [ngSrc]="src" [alt]="playerName" fill (error)="handleMissingImage($event)" />
		}
	}
</ng-template>
