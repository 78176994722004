import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function urlValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		// Return null if control is empty
		if (!control.value) {
			return null;
		}

		try {
			// Try to construct a URL object from the input
			const url = new URL(control.value);

			// Check if the protocol is either http or https
			if (!['http:', 'https:'].includes(url.protocol)) {
				return { invalidUrl: { value: control.value, reason: 'Protocol must be http or https' } };
			}

			// Check if there's a valid hostname
			if (!url.hostname) {
				return { invalidUrl: { value: control.value, reason: 'Missing hostname' } };
			}

			// URL is valid
			return null;
		} catch (error) {
			// URL construction failed, input is invalid
			return { invalidUrl: { value: control.value, reason: 'Invalid URL format' } };
		}
	};
}
