import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BUNDESLIGA_ROUTES } from '@nx-bundesliga/bundesmaster/shared';
import * as jwp from '@nx-bundesliga/shared/data-access/jwp-platform-api';
import { Observable, map, tap } from 'rxjs';
import { CORE_ENVIRONMENT } from '../../bundesmaster-core.module';
import { BundesmasterEnvironment } from '../../bundesmaster-environment';
import { ArticleHistoryList, ArticleHistoryVersion } from '../../models/article.history.model';
import { Article, ArticleCreateResponse, ArticleDeleteResponse, ArticlePublishResponse, ArticleResponse, ArticleSaveResponse, ArticleWithLocalizedVariants, EditorialTypes, GenerateMatchreportResponse, GenerateStoryResponse, StatusTypes } from '../../models/article.model';
import { Asset, AssetDeleteResponse, AssetResponse, AssetSaveResponse } from '../../models/asset.model';
import { MatchReportSuggestion, MatchReportSuggestionPromptParams, MatchReportSuggestionResponse } from '../../models/matchreport-suggestion.model';
import { BundesmasterMediaLibraryUploadItem } from '../../models/media-library.model';
import { StorySuggestion, StorySuggestionPromptParams, StorySuggestionResponse } from '../../models/story-suggestion.model';
import { MediaWithRenditions } from '../../models/video.model';

@Injectable({
	providedIn: 'root'
})
export class BundesmasterContentApiService {
	constructor(private http: HttpClient, @Inject(CORE_ENVIRONMENT) private readonly environment: BundesmasterEnvironment) {}

	/*
	 * Common Functions
	 */
	public getArticleUrl(article: Article): string {
		return `${this.environment?.domain}/${article.language}/${article.categories.includes('bundesliga-2') ? '2bundesliga' : 'bundesliga'}/${BUNDESLIGA_ROUTES[article.language]['news'] ?? 'news'}/${article.slug}-${article?.id}`;
	}

	/*
	 * Assets
	 */
	public getAssets(page = 0, size = 24, sort = 'created_date', order: 'desc' | 'asc' = 'desc', filters: any = {}): Observable<AssetResponse> {
		const params = new HttpParams({ fromObject: { ...filters, ...{ page }, ...{ size }, ...{ sort }, ...{ order } } });
		return this.http.get<AssetResponse>(`${this.environment.apis.content.url}/asset`, { params });
	}

	public getAsset(id: string | number): Observable<Asset> {
		return this.http.get<Asset>(`${this.environment.apis.content.url}/asset/${id}`);
	}

	public getAssetByUrl(url: string): Observable<Asset> {
		const urlObj = new URL(url);
		const assetUrl = urlObj.pathname?.replace(/^\//, '');
		return this.getAssets(undefined, undefined, undefined, undefined, { file: assetUrl }).pipe(
			map((res: AssetResponse) => {
				if (res?.items?.length === 0) {
					throw new Error('image not found');
				}
				return res.items[0] || null;
			})
		);
	}

	public updateAsset(id: string | number, payload: Asset): Observable<AssetSaveResponse> {
		return this.http.put<AssetSaveResponse>(`${this.environment.apis.content.url}/asset/${id}`, payload);
	}

	public deleteAsset(id: string | number): Observable<AssetDeleteResponse> {
		return this.http.delete<AssetDeleteResponse>(`${this.environment.apis.content.url}/asset/${id}`);
	}

	public startMediaUpload(filename: string, contentType: string, author?: string) {
		return this.http.get<{ uploadURL: string; key: string; fileName: string; id: string }>(`${this.environment.apis.content.url}/asset/upload?author=${author}&contentType=${contentType}&fileName=${filename}`);
	}

	public getMediaUploadStatus(id: string) {
		return this.http.get<BundesmasterMediaLibraryUploadItem>(`${this.environment.apis.content.url}/asset/upload/status/${id}`);
	}

	/*
	 * Videos
	 */
	public getVideos(page = 1, size = 24, filters: any = {}): Observable<jwp.ListMediaResponse> {
		const params = new HttpParams({ fromObject: { ...filters, ...{ page }, ...{ size } } });
		return this.http.get<jwp.ListMediaResponse>(`${this.environment.apis.content.url}/video`, { params });
	}

	public getVideo(id: string, property = 'videos'): Observable<MediaWithRenditions> {
		const params = new HttpParams().set('videoproperty', property);
		return this.http.get<MediaWithRenditions>(`${this.environment.apis.content.url}/video/${id}`, { params });
	}

	public updateVideo(video: jwp.UpdateMedia, thumbnailURL: string) {
		return this.http.post(`${this.environment.apis.content.url}/video/${video.id}`, { ...video, thumbnailURL });
	}

	/*
	 * Articles
	 */
	public getArticles(language: string, editorialType: EditorialTypes | '' = '', page = 0, size = 24, sort = 'last_publish_date', order: 'desc' | 'asc' = 'desc', filters: any = {}): Observable<ArticleResponse> {
		const params = new HttpParams({ fromObject: { ...filters, page, size, sort, order } });
		return this.http.get<ArticleResponse>(`${this.environment.apis.content.url}/articles/${language}/${editorialType}`, { params });
	}

	public getArticlesByStatus(language: string, editorialType: EditorialTypes | '' = '', status: StatusTypes, page = 0, size = 24, sort = 'last_publish_date', order: 'desc' | 'asc' = 'desc', filters: any = {}): Observable<ArticleResponse> {
		const params = new HttpParams({ fromObject: { ...filters, page, size, sort, order, status } });
		return this.http.get<ArticleResponse>(`${this.environment.apis.content.url}/articles/${language}/${editorialType}`, { params });
	}

	public getArticlesByAuthor(language: string, editorialType: EditorialTypes | '' = '', author: string, page = 0, size = 24, sort = 'last_publish_date', order: 'desc' | 'asc' = 'desc', filters: any = {}): Observable<ArticleResponse> {
		const params = new HttpParams({ fromObject: { ...filters, page, size, sort, order, author } });
		return this.http.get<ArticleResponse>(`${this.environment.apis.content.url}/articles/${language}/${editorialType}`, { params });
	}

	public getArticle(id: string | number): Observable<ArticleWithLocalizedVariants> {
		return this.http.get<ArticleWithLocalizedVariants>(`${this.environment.apis.content.url}/article/${id}`);
	}

	public generateStory(articleId: string | number, promptParams: StorySuggestionPromptParams): Observable<GenerateStoryResponse> {
		const params = new HttpParams().appendAll({ ...promptParams });
		return this.http.get<GenerateStoryResponse>(`${this.environment.apis.ai.url}/story/generate/${articleId}`, { params });
	}

	public getGeneratedStories(language: string, page = 0, size = 24, sort = 'created_date', order: 'desc' | 'asc' = 'desc', filters: any = {}): Observable<StorySuggestionResponse> {
		const params = new HttpParams({ fromObject: { ...filters, page, size, sort, order } });
		return this.http.get<StorySuggestionResponse>(`${this.environment.apis.ai.url}/story/${language}`, { params });
	}

	public getGeneratedStoriesByArticleId(language: string, articleId: number | string, limit = 10, lastRequestId?: string): Observable<StorySuggestionResponse> {
		return this.http.get<StorySuggestionResponse>(`${this.environment.apis.ai.url}/story/${language}?articleId=${articleId}&limit=${limit}${lastRequestId ? '&lastRequestId=' + lastRequestId : ''}`);
	}

	public getGeneratedArticleStory(requestId: string): Observable<StorySuggestion> {
		return this.http.get<StorySuggestion>(`${this.environment.apis.ai.url}/story?requestId=${requestId}`).pipe(
			map((story: StorySuggestion) => {
				let storyPromptImages = [];
				try {
					storyPromptImages = JSON.parse((story.prompts?.story?.images ?? '[]') as string);
				} catch (e) {
					storyPromptImages = [];
				}
				return {
					...story,
					storyType: 'article_story',
					prompts: {
						...(story.prompts ?? {}),
						story: {
							...(story.prompts?.story ?? {}),
							images: storyPromptImages as Asset[]
						},
						assignImages: Object.entries(story.prompts?.assignImages ?? {})?.reduce((acc, [key, value]) => {
							let assignImagesPromptImages = [];
							try {
								assignImagesPromptImages = JSON.parse((value?.images ?? '[]') as string);
							} catch (e) {
								assignImagesPromptImages = [];
							}
							acc[key?.toString()] = {
								...value,
								images: assignImagesPromptImages as Asset[]
							};
							return acc;
						}, {})
					}
				} as StorySuggestion;
			})
		);
	}

	public saveArticle(id: string | number, article: Partial<Article>): Observable<ArticleSaveResponse> {
		return this.http.put<ArticleSaveResponse>(`${this.environment.apis.content.url}/article/${id}`, article);
	}

	public createArticle(article: Partial<Article>): Observable<ArticleCreateResponse> {
		return this.http.post<ArticleCreateResponse>(`${this.environment.apis.content.url}/article`, article);
	}

	public deleteArticle(id: string | number): Observable<ArticleDeleteResponse> {
		return this.http.delete<ArticleDeleteResponse>(`${this.environment.apis.content.url}/article/${id}`);
	}

	// Article ready for review
	public pendingApproval(id: string | number): Observable<ArticleSaveResponse> {
		return this.http.put<ArticleSaveResponse>(`${this.environment.apis.content.url}/article/${id}/pendingapproval`, null);
	}

	// Article no longer reader for review
	public unsetPendingApproval(id: string | number): Observable<ArticleSaveResponse> {
		return this.http.put<ArticleSaveResponse>(`${this.environment.apis.content.url}/article/${id}/unsetpendingapproval`, null);
	}

	// Approve article
	public approve(id: string | number): Observable<ArticleSaveResponse> {
		return this.http.put<ArticleSaveResponse>(`${this.environment.apis.content.url}/article/${id}/approve`, null);
	}

	// Do not approve article
	public unapprove(id: string | number): Observable<ArticleSaveResponse> {
		return this.http.put<ArticleSaveResponse>(`${this.environment.apis.content.url}/article/${id}/unapprove`, null);
	}

	/*
	 * Article Publishing
	 */
	public publishArticle(articleId: string | number): Observable<ArticlePublishResponse> {
		return this.http.post<ArticlePublishResponse>(`${this.environment.apis.content.url}/article/${articleId}/publish`, {});
	}
	public unpublishArticle(articleId: string | number): Observable<ArticlePublishResponse> {
		return this.http.post<ArticlePublishResponse>(`${this.environment.apis.content.url}/article/${articleId}/unpublish`, {});
	}
	public scheduleArticle(articleId: string | number, scheduled_date: string): Observable<ArticlePublishResponse> {
		return this.http.post<ArticlePublishResponse>(`${this.environment.apis.content.url}/article/${articleId}/schedule`, { scheduled_date });
	}
	public unscheduleArticle(articleId: string | number): Observable<ArticlePublishResponse> {
		return this.http.post<ArticlePublishResponse>(`${this.environment.apis.content.url}/article/${articleId}/unschedule`, {});
	}

	/*
	 * Articles Publishing history
	 */
	public getArticleHistory(articleId: string | number): Observable<ArticleHistoryList> {
		return this.http.get<ArticleHistoryList>(`${this.environment.apis.content.url}/article/${articleId}/history`);
	}
	public getArticleHistoryId(articleId: string | number, publishedId: string | number): Observable<ArticleHistoryVersion> {
		return this.http.get<ArticleHistoryVersion>(`${this.environment.apis.content.url}/article/${articleId}/history/${publishedId}`);
	}

	/*
	 * Articles Translation
	 */
	public translateArticleV2(articleId: number, targetLanguage: string) {
		return this.http.get<{ articleId: number; language: string }>(`${this.environment.apis.content.url}/article/${articleId}/translate_v2/${targetLanguage}`);
	}

	/*
	 * Matchreports
	 */
	public generateMatchreport(promptParams: MatchReportSuggestionPromptParams): Observable<GenerateMatchreportResponse> {
		return this.http.post<GenerateMatchreportResponse>(`${this.environment.apis.ai.url}/matchreport/generate/${promptParams?.matchId}`, promptParams);
	}

	public getGeneratedMatchreports(language: string, page = 0, size = 24, sort = 'created_date', order: 'desc' | 'asc' = 'desc', filters: any = {}): Observable<MatchReportSuggestionResponse> {
		const params = new HttpParams({ fromObject: { ...filters, page, size, sort, order } });
		return this.http.get<MatchReportSuggestionResponse>(`${this.environment.apis.ai.url}/matchreport/${language}`, { params });
	}

	public getGeneratedMatchreport(requestId: string): Observable<MatchReportSuggestion> {
		return this.http.get<MatchReportSuggestion>(`${this.environment.apis.ai.url}/matchreport?requestId=${requestId}`);
	}
}
