import { AssetDimensions } from './asset.model';
import { Tags } from './tags.model';

export interface ArticleResponse {
	page: number;
	size: number;
	total: number;
	items: Article[];
}

export interface ArticleSaveResponse {
	message: string;
	item?: Article;
}

export interface ArticleCreateResponse {
	message: string;
	id: number;
}

export interface ArticleDeleteResponse {
	message: string;
}

export interface ArticlePublishResponse {
	message: string;
	item?: Article;
}

export interface GenerateStoryResponse {
	message: string;
	requestId: string;
}

export interface GenerateMatchreportResponse {
	message: string;
	requestId: string;
}

export type LocalizedVariant = Readonly<Pick<Article, 'id' | 'language'>>;

export interface ArticleWithLocalizedVariants extends Article {
	readonly localizedVariants: readonly LocalizedVariant[];
}

export interface Article {
	id: number;
	/** @ignore */
	status: StatusTypes;
	live?: boolean;
	editorialType: EditorialTypes;
	/** @nullable */
	created_date?: string | null;
	/** @nullable */
	modified_date?: string | null;
	/** @nullable */
	editorial_date: string | null;
	/** @nullable */
	last_publish_date?: string | null;
	/** @nullable */
	scheduled_date?: string | null;
	author?: string[];
	/** @nullable */
	approved_by?: string | null;
	/** @nullable */
	published_by?: string | null;
	/** @nullable */
	scheduled_by?: string | null;
	language: ArticleLanguages;
	template?: string;
	categories: string[];
	contentGroup?: ContentGroupTypes;
	// tags
	tags?: Tags;
	// headlines
	slug?: string;
	title?: string;
	description?: string;
	fullHeadline?: string;
	headline?: string;
	fullTeaser?: string;
	teaser?: string;
	abstract?: string;
	// actual content
	content?: ArticleContentBlocks[];
	// WIKI specific fields
	wikiCategories?: string[];
	localizedFromArticleId?: number;
	process?: 'translating' | null;
	vgWortPixelUrl?: string;

	// recommender
	contentLabel?: EditorialContentLabels;
	top?: boolean;

	contentSharing?: {
		oneFootball: ContentSharingPlatform;
	};
}

interface ContentSharingPlatform {
	active: boolean;
	externalId?: string | null;
	lastSyncDate?: string | null;
}

export const articleCategories: string[] = ['bundesliga', 'bundesliga-2', 'euro', 'bmf-zone', 'fixturesbl1', 'fixturesbl2', 'tablebl1', 'tablebl2', 'partners', 'awards', 'virtual-bundesliga', 'dfb-cup', '60years'];

export type ArticleLanguages = 'en' | 'de' | 'es' | 'jp' | 'fr' | 'pt' | 'ar';
export const articleLanguages: ArticleLanguages[] = ['en', 'de', 'es', 'jp', 'fr', 'pt', 'ar'];

export type ContentGroupTypes = 'news' | 'features' | 'matchday' | 'partner' | 'live' | 'games' | 'content_aggregator' | 'dfl-services' | 'a-z' | 'none' | 'other';
export const contentGroupTypes: ContentGroupTypes[] = ['news', 'features', 'matchday', 'partner', 'live', 'games', 'content_aggregator', 'dfl-services', 'a-z', 'none', 'other'];

export type StatusTypes = 'draft' | 'published' | 'scheduled' | 'unpublished' | 'pending_approval' | 'approved';
export const statusTypes: StatusTypes[] = ['published', 'scheduled', 'unpublished', 'pending_approval', 'approved'];

export type EditorialTypes = 'news' | 'live_match' | 'special_page' | 'wiki' | 'wiki_category';
export const editorialTypes: EditorialTypes[] = ['news', 'live_match', 'special_page', 'wiki', 'wiki_category'];

export type EditorialContentLabels = 'news' | 'match-preview' | 'match-review' | 'transfer' | 'evergreen' | 'no-reco';
export const editorialContentLabels: EditorialContentLabels[] = ['news', 'match-preview', 'match-review', 'transfer', 'evergreen', 'no-reco'];

export type ArticleContentBlocks =
	| ArticleContentBlockContent
	| ArticleContentBlockImage
	| ArticleContentBlockVideo
	| ArticleContentBlockMonterosa
	| ArticleContentBlockApester
	| ArticleContentBlockYoutube
	| ArticleContentBlockTwitter
	| ArticleContentBlockInstagram
	| ArticleContentBlockFacebook
	| ArticleContentBlockLivematch
	| ArticleContentBlockSingleLine
	| ArticleContentBlockMultiLine
	| ArticleContentBlockHidden
	| ArticleContentBlockTeaser
	| ArticleContentBlockContentHtml;

export interface ArticleContentBlocksListItem {
	contentBlock: string;
	display?: string;
	icon?: string;
	editorialTypes: EditorialTypes[];
	insertForbidden?: boolean;
}
export interface ArticleContentBlocksList {
	[blockType: string]: ArticleContentBlocksListItem;
}

export const articleContentBlocksList: ArticleContentBlocksList = {
	content: {
		contentBlock: 'content',
		display: 'Content (Headline + Text)',
		icon: 'description',
		editorialTypes: ['news', 'live_match', 'special_page', 'wiki', 'wiki_category']
	},
	image: {
		contentBlock: 'image',
		display: 'Image',
		icon: 'camera_alt',
		editorialTypes: ['news', 'live_match', 'special_page', 'wiki', 'wiki_category']
	},
	video: {
		contentBlock: 'video',
		display: 'Video',
		icon: 'videocam',
		editorialTypes: ['news', 'live_match', 'special_page', 'wiki', 'wiki_category']
	},
	monterosa: {
		contentBlock: 'monterosa',
		display: 'Monterosa',
		icon: 'public',
		editorialTypes: ['news', 'live_match', 'wiki', 'wiki_category']
	},
	apester: {
		contentBlock: 'apester',
		display: 'Apester',
		icon: 'public',
		editorialTypes: ['news', 'live_match', 'wiki', 'wiki_category']
	},
	youtube: {
		contentBlock: 'youtube',
		display: 'Youtube',
		icon: 'public',
		editorialTypes: ['news', 'live_match', 'wiki', 'wiki_category']
	},
	twitter: {
		contentBlock: 'twitter',
		display: 'Twitter',
		icon: 'public',
		editorialTypes: ['news', 'live_match', 'wiki', 'wiki_category']
	},
	instagram: {
		contentBlock: 'instagram',
		display: 'Instagram',
		icon: 'public',
		editorialTypes: ['news', 'live_match', 'wiki', 'wiki_category']
	},
	facebook: {
		contentBlock: 'facebook',
		display: 'Facebook',
		icon: 'public',
		editorialTypes: ['news', 'live_match', 'wiki', 'wiki_category'],
		insertForbidden: true
	},
	live_match_id: {
		contentBlock: 'live_match_id',
		display: 'Liveticker Match',
		icon: 'public',
		editorialTypes: ['live_match']
	},
	teaser: {
		contentBlock: 'teaser',
		display: 'Article Teaser',
		icon: 'public',
		editorialTypes: ['special_page']
	},
	'single-line': {
		contentBlock: 'single-line',
		display: 'Single-Line',
		icon: 'description',
		editorialTypes: ['special_page']
	},
	'multi-line': {
		contentBlock: 'multi-line',
		display: 'Multi-Line',
		icon: 'description',
		editorialTypes: ['special_page']
	},
	hidden: {
		contentBlock: 'hidden',
		display: 'Hidden Content',
		icon: 'description',
		editorialTypes: ['special_page']
	},
	content_html: {
		contentBlock: 'content_html',
		display: 'Special Page Content w/ HTML',
		icon: 'table',
		editorialTypes: ['special_page']
	}
};

export type ArticleImageUrls = {
	[dimension in AssetDimensions]?: {
		url: string;
	};
};

export interface ArticleImage extends ArticleImageUrls {
	copyright?: string;
	caption?: string;
	alt?: string;
}

export interface ArticleContentBlockContent {
	blockType: 'content';
	paragraph?: string;
	heading?: string;
}

export interface ArticleContentBlockContentHtml {
	blockType: 'content_html';
	html?: string;
	heading?: string;
}

export interface ArticleContentBlockImage {
	blockType: 'image';
	image: ArticleImage;
}

export interface ArticleContentBlockVideo {
	blockType: 'video';
	vid: string;
	duration: number;
	description?: string;
	autoplay: boolean;
	image: ArticleImage;
	uploadDate?: string;
}
export interface ArticleContentBlockEmbed {
	snippet?: string;
	url: string;
}
export interface ArticleContentBlockMonterosa {
	blockType: 'monterosa';
	eventId: string;
	projectId?: string;
	channelsEnabled?: {
		mobile?: boolean;
		web?: boolean;
	};
	placeholder?: {
		target: string;
		image: ArticleImage;
	};
}
export interface ArticleContentBlockApester extends ArticleContentBlockEmbed {
	blockType: 'apester';
}
export interface ArticleContentBlockYoutube extends ArticleContentBlockEmbed {
	blockType: 'youtube';
}
export interface ArticleContentBlockTwitter extends ArticleContentBlockEmbed {
	blockType: 'twitter';
}
export interface ArticleContentBlockInstagram extends ArticleContentBlockEmbed {
	blockType: 'instagram';
}
export interface ArticleContentBlockFacebook extends ArticleContentBlockEmbed {
	blockType: 'facebook';
}
export interface ArticleContentBlockLivematch {
	blockType: 'live_match_id';
	liveMatchId: string;
}

export interface ArticleContentBlockSingleLine {
	blockType: 'single-line';
	heading: string;
}
export interface ArticleContentBlockMultiLine {
	blockType: 'multi-line';
	paragraph: string;
}
export interface ArticleContentBlockHidden {
	blockType: 'hidden';
	hiddenText: string;
}

export interface ArticleContentBlockTeaser {
	blockType: 'teaser';
	image: ArticleImage;
	target: string;
	relationship: string;
	isPinned?: boolean;
	hasVideo: boolean;
	parameters: string;
	text: string;
	textShort: string;
	headline: string;
	headlineShort: string;
}
