import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

import { EditorModule } from '@tinymce/tinymce-angular';
import { MatTinyMCEComponent } from './mat-tinymce.component';

@NgModule({
	declarations: [MatTinyMCEComponent],
	exports: [MatTinyMCEComponent],
	imports: [CommonModule, FormsModule, ReactiveFormsModule, EditorModule, MatFormFieldModule]
})
export class MatTinyMCEModule {}
