<button class="w-100" [type]="type" [disabled]="loading || disabled" [ngClass]="{ 'mat-flat-button': buttonStyle === 'flat', 'mat-stroked-button': buttonStyle === 'stroked', disabled: loading || disabled }" [ngStyle]="{ color: color, backgroundColor: backgroundColor }">
	<span class="wrapper">
		@if (loading) {
			<mat-progress-spinner [mode]="spinnerMode" [diameter]="spinnerDiameter"></mat-progress-spinner>
		}
		<span class="content" [ngClass]="{ 'small-space-page': isSmallerSpacePage }" [ngStyle]="{ 'justify-content': justifyStyle }">
			<ng-content></ng-content>
		</span>
	</span>
</button>
