import { Article } from './article.model';
import { Asset } from './asset.model';
import { OpenSearchArticleStory } from './story.model';

export type StorySuggestionLanguages = 'en' | 'de';
export const storySuggestionLanguagesOptions: StorySuggestionLanguages[] = ['en', 'de'];

export interface StorySuggestionResponse {
	page: number;
	size: number;
	total: number;
	items: StorySuggestion[];
}

export type StorySuggestionStatus = 'QUEUED' | 'PROCESSING' | 'SUCCESS' | 'FAILED';

export type SlideScene = 'interview' | 'player' | 'goal' | 'celebration' | 'tackling' | 'action' | 'stadium' | 'unknown';

export interface StorySuggestionPromptParams {
	minSlides?: string | number;
	maxSlides?: string | number;
	maxContentSlides?: string | number;
	maxVideoSlides?: string | number;
	maxMonterosaSlides?: string | number;
	maxQuoteSlides?: string | number;
	targetLanguage?: StorySuggestionLanguages;
	persona?: StorySuggestionPromptPersona;
	additionalInstructions?: string;
}

export type StorySuggestionPromptPersona = 'NONE' | 'ENTHUSIAST' | 'FAN' | 'RIVAL_FAN' | 'NEUTRAL' | 'UNINTERESTED' | 'ANALYST' | 'CELEBRITY' | 'COMEDIAN' | 'PODCASTER' | 'SOCIAL_MEDIA_INFLUENCER' | 'SPORTS_JOURNALIST' | 'NEWBIE';
export const StorySuggestionPersonaOptions: { [persona: string | StorySuggestionPromptPersona]: string } = {
	'NONE': 'NONE: Provide no particular persona',
	'ENTHUSIAST':
		'ENTHUSIAST: Write with infectious enthusiasm and passion. Use dynamic and vivid language to create engaging titles and descriptions. Employ an upbeat and optimistic tone throughout. Convey excitement through word choice and punctuation. Emphasize the most thrilling or significant aspects of the news. Use superlatives judiciously to highlight exceptional points. Maintain an energetic pace in your writing.',
	'FAN': 'FAN: Use "we" and "our" for the team. Include club-specific nicknames. Express strong emotions about results in descriptions. Highlight club history and traditions where relevant.',
	'RIVAL_FAN': 'RIVAL_FAN: Maintain a respectful tone while expressing mild rivalry. Focus on factual information in descriptions.',
	'NEUTRAL': 'NEUTRAL: Present facts objectively. Use precise language without emotional coloring. Provide balanced perspectives on issues. Focus on league-wide implications of events.',
	'UNINTERESTED': 'UNINTERESTED: Use basic, non-technical language for soccer events. Focus more on off-field or non-sporting aspects in descriptions. Keep explanations simple and relatable.',
	'ANALYST': 'ANALYST: Use soccer jargon appropriately. Focus on tactical aspects and performance metrics in descriptions. Compare strategies when relevant.',
	'CELEBRITY': 'CELEBRITY: Adopt a casual tone. Use current soccer-related slang tastefully. Express opinions confidently in descriptions.',
	'COMEDIAN': 'COMEDIAN: Incorporate light humor and wordplay in titles and descriptions where appropriate, without compromising information integrity.',
	'PODCASTER': 'PODCASTER: Use a conversational tone. Pose thought-provoking questions about news implications in descriptions.',
	'SOCIAL_MEDIA_INFLUENCER': 'SOCIAL_MEDIA_INFLUENCER: Create engaging, concise titles. Use trendy phrases in descriptions when appropriate.',
	'SPORTS_JOURNALIST': 'SPORTS_JOURNALIST: Write in a formal, objective tone. Prioritize key information in titles and descriptions. Provide context where necessary.',
	'NEWBIE': 'NEWBIE: Explain soccer terms simply in descriptions. Provide brief background on teams or players when necessary.'
};

export interface StorySlideSuggestionImageSuggestion {
	depiction?: string;
	search?: string;
}

export interface StorySlideSuggestionImage {
	url: string;
	copyright: string;
}

export interface StoryQuoteSlideSuggestion {
	type: 'quote';
	title?: string;
	quote: string;
	actors?: string[];
	scene?: SlideScene;
	image?: StorySlideSuggestionImage;
	imageAssignment?: string;
	imageSuggestion?: StorySlideSuggestionImageSuggestion;
}

export interface StoryTextSlideSuggestion {
	type: 'text';
	title?: string;
	description: string;
	actors?: string[];
	scene?: SlideScene;
	image?: StorySlideSuggestionImage;
	imageAssignment?: string;
	imageSuggestion?: StorySlideSuggestionImageSuggestion;
}

export interface StoryVideoSlideSuggestion {
	type: 'video';
	title?: string;
	description: string;
	videoAssignment?: string;
	video?: {
		vid: string;
		duration: number;
	};
}

export interface StoryMonterosaSlideSuggestion {
	type: 'monterosa';
	title?: string;
	description: string;
	monterosaAssignment?: string;
}

export type StorySlideSuggestion = StoryQuoteSlideSuggestion | StoryTextSlideSuggestion | StoryVideoSlideSuggestion | StoryMonterosaSlideSuggestion;
export type StorySlideSuggestionWithImage = StoryQuoteSlideSuggestion | StoryTextSlideSuggestion;

export interface StorySuggestion {
	id: string;
	requestId: string;
	/** @nullable */
	articleId?: string | null;
	/** @nullable */
	language?: StorySuggestionLanguages | null;
	/** @nullable */
	article?: Article | null;
	/** @nullable */
	story?: OpenSearchArticleStory | null;
	/** @nullable */
	storySlideSuggestion?: StorySlideSuggestion[] | null;
	/** @nullable */
	promptParams?: StorySuggestionPromptParams | null;
	/** @nullable */
	modified_date?: string | null;
	/** @nullable */
	created_date?: string | null;
	/** @nullable */
	errorMessage?: any | null;
	/** @nullable */
	status?: StorySuggestionStatus;
	/** @nullable */
	prompts?: {
		story?: {
			prompt?: string | null;
			response?: any | null;
			images?: Asset[] | null;
		};
		assignImages?: {
			[slide: string]: {
				prompt?: string | null;
				response?: any | null;
				images?: Asset[] | null;
				assignImage?: string | null;
			};
		};
	};
}
