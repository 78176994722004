import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { BundesmasterUserRole } from '@nx-bundesliga/bundesmaster/shared';
import { map } from 'rxjs';
import { UserAuthenticationService } from '../service/user-authentication.service';

export const roleBasedAuthorizerGuard = (role: BundesmasterUserRole, languages?: string[]): CanActivateFn => {
	const neededPowerLevel = role.powerLevel;

	return () => {
		return inject(UserAuthenticationService).user$.pipe(map((user) => (user?.role.powerLevel ?? 0) >= neededPowerLevel && (!languages || !!languages?.some((l) => (user?.allowedLanguages ?? []).some((language) => language.isLanguage(l))))));
	};
};
