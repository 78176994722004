import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'dateDiff',
	pure: true
})
export class DateDiffPipe implements PipeTransform {
	transform(startDate: string | Date, endDate: string | Date): number {
		const start = new Date(startDate);
		const end = new Date(endDate);
		const diffMs = Math.abs(end.getTime() - start.getTime()); // Use absolute value
		return Math.round(diffMs / 1000); // Convert milliseconds to seconds and round
	}
}
