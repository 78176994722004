import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { Asset, AssetResponse, BundesmasterContentApiService } from '@nx-bundesliga/bundesmaster/core';
import { SortEvent } from '@nx-bundesliga/bundesmaster/ui/mat-paginator-goto';
import { SearchQuery } from '@nx-bundesliga/bundesmaster/ui/search-bar';
import { format } from 'date-fns';
import { Observable, ReplaySubject, catchError, concatMap, of, take, tap } from 'rxjs';

@Component({
	selector: 'nx-bundesliga-media-library-overview',
	templateUrl: './media-library-overview.component.html',
	styleUrls: ['./media-library-overview.component.scss']
})
export class MediaLibraryOverviewComponent implements OnInit {
	public assets$: Observable<AssetResponse> = new Observable<AssetResponse>();
	public navigation$: ReplaySubject<{ page: number; size: number }> = new ReplaySubject<{ page: number; size: number }>(1);
	public query$: Observable<any>;
	@Output() assetClicked: EventEmitter<Asset> = new EventEmitter<Asset>();

	public size = 24;
	public page = 0;

	public articleSortOptions = ['created_date', 'modified_date'];
	public sort = 'created_date';
	public order: 'desc' | 'asc' = 'desc';

	public loading = true;
	public initialLoad = true;

	public selectFilter = {
		media_type: [
			{ name: 'image', value: 'image' },
			{ name: 'file', value: 'file' }
		],

		mime_type: [
			{ name: 'jpeg', value: 'image/jpeg' },
			{ name: 'gif', value: 'image/gif' },
			{ name: 'png', value: 'image/png' },
			{ name: 'webp', value: 'image/webp' },
			{ name: 'pdf', value: 'application/pdf' },
			{ name: 'mp3', value: 'audio/mpeg' },
			{ name: 'mp4', value: 'video/mp4' },
			{ name: 'word', value: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
			{ name: 'excel', value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
		]
	};

	constructor(
		private assetService: BundesmasterContentApiService,
		private router: Router,
		private route: ActivatedRoute
	) {
		this.assets$ = this.route.queryParams.pipe(
			takeUntilDestroyed(),
			tap(() => (this.loading = true)),
			concatMap(({ page, size, sort, order, ...filter }) => {
				this.page = parseInt(page, 10) || 0;
				this.size = parseInt(size, 10) || 24;
				this.sort = sort || 'created_date';
				this.order = order || 'desc';
				return this.assetService.getAssets(page, size, sort, order, filter).pipe(
					catchError(() => of({ size: 0, page: 0, total: 0, items: [] })),
					tap(() => {
						this.loading = false;
						this.initialLoad = false;
					})
				);
			}),
			tap((assets) => {
				this.loading = false;
				this.initialLoad = false;
				console.log('assets:', assets);
			})
		);
	}

	ngOnInit() {
		this.query$ = this.route.queryParams.pipe(take(1));
	}

	onSearchQueryChanged(event: SearchQuery) {
		const dateRangeStart = event.dateRange?.start ? new Date(event.dateRange?.start) : '';
		const dateRangeEnd = event.dateRange?.end ? new Date(event.dateRange?.end) : '';
		const rangeStart = dateRangeStart instanceof Date ? format(dateRangeStart, 'yyyy-MM-dd') : '';
		const rangeEnd = dateRangeEnd instanceof Date ? format(dateRangeEnd, 'yyyy-MM-dd') : '';

		const filters = {
			...(event.search && { search: event.search }),
			...(event.search_semantic && { search_semantic: event.search_semantic }),
			...((rangeStart || rangeEnd) && { dateRange: `${rangeStart}_${rangeEnd}` }),
			...(event.filter.clubs?.length > 0 && { clubs: event.filter.clubs.join(',') }),
			...(event.filter.players?.length > 0 && { players: event.filter.players.join(',') }),
			...(event.filter.seasons?.length > 0 && { seasons: event.filter.seasons.join(',') }),
			...(event.filter.competitions?.length > 0 && { competitions: event.filter.competitions.join(',') }),
			...(event.filter.matchdays?.length > 0 && { matchdays: event.filter.matchdays.join(',') }),
			...(event.filter.matches?.length > 0 && { matches: event.filter.matches.join(',') }),
			...(event.filter.tags?.length > 0 && { tags: event.filter.tags.join(',') }),
			...(event.filter.motif?.length > 0 && { motif: event.filter.motif.join(',') }),
			...(event.filter.llmTags?.length > 0 && { llmTags: event.filter.llmTags.join(',') }),
			...(event.selectFilter?.['mime_type'] && { mime_type: event.selectFilter?.['mime_type'] }),
			...(event.selectFilter?.['media_type'] && { media_type: event.selectFilter?.['media_type'] })
		};

		if (this.initialLoad === false) {
			this.page = 0;
			// console.log('navigate filters', {...filters, size: this.size, page: this.page});
			this.router.navigate([], {
				relativeTo: this.route,
				queryParams: { ...filters, size: this.size, page: this.page, sort: this.sort, order: this.order, ...(event.search || event.search_semantic ? { sort: '_score' } : {}) }
			});
		}
	}

	onPageinationChanged(event: PageEvent) {
		this.size = event.pageSize;
		this.page = event.pageIndex;
		// console.log('navigate pagination', { size: this.size, page: this.page });
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: { size: this.size, page: this.page },
			queryParamsHandling: 'merge'
		});
	}

	onSortChanged(event: SortEvent) {
		this.sort = event.sort;
		this.order = event.order;
		// console.log('navigate pagination', { size: this.size, page: this.page });
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: { sort: this.sort, order: this.order },
			queryParamsHandling: 'merge'
		});
	}
}
