
    export const buildVars = {
      version: '1.8.0',
      build: '9726',
      angularVersion: '18.2.8',
	  nxVersion: '19.8.4',
      commitId: '83d903c18',
      commitTime: '2024-12-11 11:03:37 +0000',
	  branch: 'main'
    };
    