import { Clipboard } from '@angular/cdk/clipboard';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeAr from '@angular/common/locales/ar';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeJa from '@angular/common/locales/ja';
import localePt from '@angular/common/locales/pt';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { UserAuthenticationService } from '@nx-bundesliga/bundesmaster/authorizer';
import { BUNDESLIGA_SEASONNAMES, Competition, editorialTypes, selectCompetitionState, selectLanguageState, setCompetition, setLanguage, storyTypes } from '@nx-bundesliga/bundesmaster/core';
import { BUNDESLIGA_COMPETITIONS } from '@nx-bundesliga/models';
import { Observable, Subject, combineLatest, map, shareReplay } from 'rxjs';
import { environment } from '../environments/environment';

@Component({
	selector: 'bundesmaster-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	public title = 'nx-bundesmaster'; // does this trigger an deployment via nx-affected?
	public buildVars = environment.buildVars;
	public stage = environment.stage;
	public bundesmasterUser$ = this.authorizer.user$;
	public competition$ = this.store.select(selectCompetitionState);
	public activeLanguages$ = combineLatest([
		this.authorizer.user$,
		this.store.select(selectLanguageState).pipe(
			map((languageState) => {
				return Object.values(languageState.available)
					.map((language) => ({ ...language, active: languageState.active === language.code }))
					.sort((a, b) => a.order - b.order);
			}),
			map((languageState) => {
				return languageState;
			})
		)
	]).pipe(map(([{ allowedLanguages, role }, languages]) => (role.isGuest ? languages.filter((language) => allowedLanguages.some((l) => l.isLanguage(language.code))) : languages)));

	private readonly destroying$ = new Subject<void>();
	public editorialTypes: string[] = editorialTypes;
	public storyTypes: string[] = storyTypes;
	public readonly clubsPageLinkDefaultSeason = BUNDESLIGA_SEASONNAMES.CURRENT;

	isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium]).pipe(
		map((result) => result.matches),
		shareReplay()
	);

	constructor(private authorizer: UserAuthenticationService, private http: HttpClient, private sanitizer: DomSanitizer, private breakpointObserver: BreakpointObserver, private store: Store, private clipboard: Clipboard) {
		registerLocaleData(localeDe, 'de');
		registerLocaleData(localeJa, 'ja');
		registerLocaleData(localeEs, 'es');
		registerLocaleData(localeEn, 'en');
		registerLocaleData(localeFr, 'fr');
		registerLocaleData(localePt, 'pt');
		registerLocaleData(localeAr, 'ar');
	}

	setCompetition(name: string) {
		this.store.dispatch(setCompetition({ name }));
	}

	getCompetitionDisplayOrder({ id }: Competition): number {
		switch (id) {
			case BUNDESLIGA_COMPETITIONS.BUNDESLIGA:
				return 1;
			case BUNDESLIGA_COMPETITIONS.BUNDESLIGA2:
				return 2;
			default:
				return 3;
		}
	}

	setLanguage(code: string) {
		this.store.dispatch(setLanguage({ code }));
	}

	startLogin() {
		this.authorizer.login();
	}

	copyDebugInfo() {
		this.clipboard.copy(
			JSON.stringify({
				bundesmaster: {
					...this.buildVars
				},
				state: {}
			})
		);
	}
}
