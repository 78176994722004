import { OpenSearchCompactClub } from './open-search-club';
import { OpenSearchSeason } from './open-search-season';

export interface OpenSearchPersonClub extends OpenSearchCompactClub {
	readonly shirtNumber?: number;
}

export type OpenSearchPersonType = 'player' | 'teamofficial' | 'referee';

export interface OpenSearchPerson {
	readonly personId: string;
	readonly firstName: string;
	readonly lastName: string;
	readonly fullName: string;
	readonly aliasName?: string;
	readonly clubsBySeason: { readonly [seasonId: string]: readonly OpenSearchPersonClub[] };
	readonly clubs: readonly OpenSearchPersonClub[];
	readonly lastActiveClub?: OpenSearchPersonClub;
	readonly firstActiveSeason: OpenSearchSeason;
	readonly lastActiveSeason: OpenSearchSeason;
	readonly searchTerms: string[];
	readonly onlyEngagedInBlacklistedCompetitions?: boolean;
	readonly type?: OpenSearchPersonType;
}
