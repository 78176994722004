export interface AnalyticsTrack {
	action: string;
	event?: string;
	category?: string;
	label?: string;
	value?: string;
	noninteraction?: boolean;
	custom?: any; // add custom dimensions by key: value to be added to trackevent
	page_title?: string;
	page_url?: string;
	event_category?: string;
	abTestVersion?: string;
	eventType?: string;
	eventName?: string;
	amplitudeEventName?: string;
}
export interface AnalyticsExceptionTrack {
	appId: string;
	appName: string;
	appVersion: string;
	description?: string;
	fatal?: boolean;
	event?: any;
}
export interface AnalyticsPageTrack {
	path: string;
	custom?: any; // add custom dimensions by key: value to be added to pagetrackevent
}

export interface IAnalytics {
	track(event: AnalyticsTrack): void;
}

export interface AnalyticsWebviewTrack {
	event: string;
	screen_name: string;
	event_category: string;
	product: string; // allowed values: "Web BL", "App BL", "Fantasy Web", "Fantasy App"
	appInstallationId?: string;
	appVisitorId?: string;
	operating_system?: string; // allowed values: "android", "ios"
	screen_type?: 'webview' | 'web'; //allowed 'webview', 'web'
	webview_url?: string;
}

export type AmplitudeCompetition = 'Bundesliga' | 'Bundesliga 2' | 'Euro Championship' | 'Other';

export interface AmplitudePageViewEvent {
	provider: 'amplitude';
	event: 'page_view';
	eventData: {
		page_url: string; // url of the current screen
		competition: AmplitudeCompetition;
		page_type: string; // The category for the current page (for example, "Article").
		page_name?: string; // The title of the current page.
		media_id?: string; // for videos from jwp: "mediaid": e.g. "0Gw4qQJD"; For articles please return Editorial Number of the Article
		event_category?: string; // Event Category for organizing events in Tracking & Analytics Tool
	};
	userData: {
		user_ciam_id?: string; // okta id of the user
		user_cdp_opt_out?: boolean; // Whether the user has opted out for cdp stiching
		user_login_status: boolean; // Values for this user property should be: - "true" if user is logged-in - "false"
		user_favorite_club?: string; // Sts club id ("DFL-CLU-000008" for 1. FC Köln), to identify the selected favorite club
		user_follow_additional_clubs?: string; // If the user selected to follow additional clubs, this property needs to contain clubs names: atletico madrid
		user_year_of_birth?: number; // Year of birth indicated by the user when filling in the profile
		user_newsletter_bundesliga_opt_in?: boolean; // Whether the user has enabled the newsletter or not.
		user_newsletter_partner_opt_in?: boolean; // Whether the user has enabled the partner opt-in or not
		user_newsletter_fantasy_opt_in?: boolean; // Whether the user has enabled the fantasy opt-in or not
		user_login_provider?: string; // Returns the Method the user selected to login or to signup
		user_web_language?: string; // Returns the language of the Website as ISO Code
		user_country?: string; // Returns the country of the user as ISO Code
		// DFL_crossProductProfileBuildingOptout
	};
}

export type AmplitudeABTestVersion = 'A' | 'B' | 'C';

export interface AmplitudeEventClick {
	provider: string;
	event: string; // for example 'fixtures_click';
	eventData: {
		click_value: string; // matches {{match_sts_id}} for Match Bar click, Goal Video Name for Goal Bar click, {{club_sts_id}} for Club Bar click. The value of the clicked element (for example, for Match Bar click it is a Match Name)
		click_type: string; // The type of the internal click (describes the element that was clicked).
		event_category?: string; //Event Category for organizing events in Tracking & Analytics Tool
		abtest_version?: AmplitudeABTestVersion;
	};
}

export interface AmplitudeEventInteraction {
	provider: string;
	event: string; // for example 'fixtures_interaction' | 'table_calculator_interaction';
	eventData: {
		value: string; // matches {{match_sts_id}} for Match Bar click, Goal Video Name for Goal Bar click, {{club_sts_id}} for Club Bar click. The value of the clicked element (for example, for Match Bar click it is a Match Name)
		action: string; // The type of the internal click (describes the element that was clicked).
		event_category?: string; //Event Category for organizing events in Tracking & Analytics Tool
		abtest_version?: AmplitudeABTestVersion;
	};
}
