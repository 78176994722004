import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule, TaggingDialogInput, TaggingDialogResult, Tags, TagsTypes } from '@nx-bundesliga/bundesmaster/core';
import { Subject, debounceTime, filter, takeUntil } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { FormBuilder } from '@angular/forms';
import { LlmImageTagEnum } from '@nx-bundesliga/bundesmaster/shared';
import { BundesmasterUiDialogSelectClubsComponent } from '@nx-bundesliga/bundesmaster/ui/dialog-select-clubs';
import { BundesmasterUiDialogSelectCompetitionsComponent } from '@nx-bundesliga/bundesmaster/ui/dialog-select-competitions';
import { BundesmasterUiDialogSelectMatchdaysComponent } from '@nx-bundesliga/bundesmaster/ui/dialog-select-matchdays';
import { BundesmasterUiDialogSelectMatchesComponent } from '@nx-bundesliga/bundesmaster/ui/dialog-select-matches';
import { BundesmasterUiDialogSelectPersonsComponent } from '@nx-bundesliga/bundesmaster/ui/dialog-select-persons';
import { BundesmasterUiDialogSelectSeasonsComponent } from '@nx-bundesliga/bundesmaster/ui/dialog-select-seasons';
import { BundesmasterUiImageMotifDialogComponent, ImageMotifDialogInput, ImageMotifDialogOutput } from './image-motif-dialog/bundesmaster-ui-image-motif-dialog.component';
import { BundesmasterUiLlmTagDialogComponent, LlmTagDialogInput, LlmTagDialogOutput } from './llm-tag-dialog/bundesmaster-ui-llm-tag-dialog.component';
import { SearchQuery } from './search-bar.model';

@Component({
	selector: 'bundesmaster-ui-search-bar',
	standalone: true,
	imports: [
		CommonModule,
		MatInputModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatFormFieldModule,
		MatDatepickerModule,
		MatChipsModule,
		MatSelectModule,
		MatButtonModule,
		MatDialogModule,
		MatTooltipModule,
		BundesmasterUiDialogSelectCompetitionsComponent,
		BundesmasterUiDialogSelectSeasonsComponent,
		ReactiveFormsModule,
		FormsModule,
		PipesModule
	],
	templateUrl: './bundesmaster-ui-search-bar.component.html',
	styleUrls: ['./bundesmaster-ui-search-bar.component.scss']
})
export class BundesmasterUiSearchBarComponent implements OnDestroy {
	// public selectFilter: { [key: string]: { value: string; name: string }[] } = {};
	@Input() loading = false;
	@Input() enableFilter = true;
	@Input() enableSemanticSearch = false;
	@Input() enableMotifFilter = false;
	@Input() enableLlmTagFilter = false;
	@Input() enableDateRange = true;
	@Input() enableSelectFilter = true;
	@Input() set selectFilter(selectFilter: { [key: string]: { value: string; name: string }[] }) {
		this._selectFilter = selectFilter;
		// this.form.controls.selectFilter.reset();
		Object.entries(selectFilter).forEach(([filterName, valuesArray]) => {
			this.form.controls.selectFilter.addControl(filterName, this.formBuilder.control(''), { emitEvent: false });
		});
	}
	@Input() set filter(filter: { [key: string]: string[] }) {
		if (filter) {
			this._filter = filter;
			Object.entries(filter).forEach(([filterName, valuesArray]) => {
				this.form.controls.filter.get(filterName).patchValue(valuesArray, { onlySelf: true, emitEvent: false });
			});
		}
	}
	@Input() set search(query: string) {
		if (query) {
			this._search = query;
			this.form.controls.search.patchValue(query, { onlySelf: true, emitEvent: false });
		}
	}
	@Input() set search_semantic(query: string) {
		if (query) {
			this._search_semantic = query;
			this.form.controls.search_semantic.patchValue(query, { onlySelf: true, emitEvent: false });
		}
	}
	@Input() set dateRange(query: any) {
		if (query) {
			this._dateRange = query;
			this.form.controls.dateRange.patchValue(query, { onlySelf: true, emitEvent: false });
		}
	}
	@Input() set query(queryParams: any) {
		if (queryParams && Object.keys(queryParams).length > 0) {
			const { search, search_semantic, dateRange, clubs, players, seasons, competitions, matchdays, matches, tags, page, size, motif, llmTags, ...rest } = queryParams;
			this.search = search || '';
			this.search_semantic = search_semantic || '';
			this.dateRange = (dateRange && dateRange?.split('_') && { start: new Date(dateRange?.split('_')[0] || null), end: new Date(dateRange.split('_')[1] || null) }) || {};
			this.filter = {
				categories: [],
				...(clubs && { clubs: clubs.split(',') }),
				...(players && { players: players.split(',') }),
				...(seasons && { seasons: seasons.split(',') }),
				...(competitions && { competitions: competitions.split(',') }),
				...(matchdays && { matchdays: matchdays.split(',') }),
				...(matches && { matches: matches.split(',') }),
				...(tags && { tags: tags.split(',') }),
				...(motif && { motif: motif.split(',') }),
				...(llmTags && { llmTags: llmTags.split(',') })
			};
			if (rest) {
				Object.entries(rest).forEach(([key, value]) => {
					if (this.form.controls.selectFilter.get(key)) {
						this.form.controls.selectFilter.get(key).setValue(value);
					} else {
						this.form.controls.selectFilter.addControl(key, this.formBuilder.control(value), { emitEvent: false });
					}
				});
			}
			this.form.updateValueAndValidity();
		}
	}
	@Output() queryChanged: EventEmitter<SearchQuery> = new EventEmitter<SearchQuery>();

	public _selectFilter: { [key: string]: { value: string; name: string }[] } = {};
	public _filter: { [key: string]: string[] } = {};
	public _search = '';
	public _search_semantic = '';
	public _dateRange: any = {};

	private readonly destroying$ = new Subject<void>();
	public readonly separatorKeysCodes = [ENTER, COMMA] as const;

	public form = this.formBuilder.group({
		search: this.formBuilder.control<string>('', []),
		search_semantic: this.formBuilder.control<string>('', []),
		filter: this.formBuilder.group({
			categories: this.formBuilder.control<string[]>([], []),
			competitions: this.formBuilder.control<string[]>([], []),
			clubs: this.formBuilder.control<string[]>([], []),
			seasons: this.formBuilder.control<string[]>([], []),
			matchdays: this.formBuilder.control<string[]>([], []),
			matches: this.formBuilder.control<string[]>([], []),
			players: this.formBuilder.control<string[]>([], []),
			tags: this.formBuilder.control<string[]>([], []),
			motif: this.formBuilder.control<readonly string[]>([], []),
			llmTags: this.formBuilder.control<readonly LlmImageTagEnum[]>([], [])
		}),
		selectFilter: this.formBuilder.group({}),
		dateRange: this.formBuilder.group({
			start: this.formBuilder.control<Date>(null, []),
			end: this.formBuilder.control<Date>(null, [])
		})
	});

	constructor(
		private dialog: MatDialog,
		private formBuilder: FormBuilder
	) {
		this.form.valueChanges.pipe(debounceTime(200), distinctUntilChanged(), takeUntil(this.destroying$)).subscribe((value: SearchQuery) => {
			this.queryChanged.emit(this.form.getRawValue() as SearchQuery);
		});
	}

	ngOnDestroy() {
		this.destroying$.next();
		this.destroying$.complete();
	}

	searchSubmit() {
		this.form.controls.search.setValue(this._search);
	}

	searchSemanticSubmit() {
		this.form.controls.search_semantic.setValue(this._search_semantic);
	}

	addTagToGroup(tagName: TagsTypes, value: string) {
		const tagForm = this.form.controls.filter.controls[tagName];
		tagForm.setValue([...(tagForm.value || []), value]);
	}

	removeTag(tagName: string | undefined, tagValue: string): void {
		if (tagName && tagValue) {
			const index = (this.form.controls.filter.controls[tagName].value || []).findIndex((val: string) => val === tagValue);
			if (index >= 0) {
				const items = this.form.controls.filter.controls[tagName].value || [];
				this.form.controls.filter.controls[tagName].setValue([...items.slice(0, index), ...items.slice(index + 1)]);
			}
		}
	}

	clearTags() {
		Object.keys(this.form.controls.filter.controls).forEach((tagName: string) => {
			this.form.controls.filter.controls[tagName].setValue([]);
		});
	}

	addTagFilter(event: MatChipInputEvent): void {
		const value = (event.value || '').trim();

		if (value) {
			this.addTagToGroup('tags', value);
		}

		// Clear the input value
		event.chipInput?.clear();
	}

	addMotifFilter() {
		this.dialog
			.open<unknown, ImageMotifDialogInput, ImageMotifDialogOutput>(BundesmasterUiImageMotifDialogComponent, {
				maxWidth: '90vw',
				maxHeight: '90vh',
				width: '90%',
				data: {
					selected: this.form.controls.filter.controls.motif.value
				}
			})
			.afterClosed()
			.pipe(filter((result) => !!result))
			.subscribe(({ selected }) => this.form.controls.filter.controls.motif.setValue(selected));
	}

	addLlmTagFilter() {
		this.dialog
			.open<unknown, LlmTagDialogInput, LlmTagDialogOutput>(BundesmasterUiLlmTagDialogComponent, {
				maxWidth: '90vw',
				maxHeight: '90vh',
				width: '90%',
				data: {
					selected: this.form.controls.filter.controls.llmTags.value
				}
			})
			.afterClosed()
			.pipe(filter((result) => !!result))
			.subscribe(({ selected }) => this.form.controls.filter.controls.llmTags.setValue(selected));
	}

	addFilterByDialog(tag: keyof Tags) {
		let dialogComponent = null;
		switch (tag) {
			case 'clubs':
				dialogComponent = BundesmasterUiDialogSelectClubsComponent;
				break;
			case 'players':
				dialogComponent = BundesmasterUiDialogSelectPersonsComponent;
				break;
			case 'competitions':
				dialogComponent = BundesmasterUiDialogSelectCompetitionsComponent;
				break;
			case 'seasons':
				dialogComponent = BundesmasterUiDialogSelectSeasonsComponent;
				break;
			case 'matchdays':
				dialogComponent = BundesmasterUiDialogSelectMatchdaysComponent;
				break;
			case 'matches':
				dialogComponent = BundesmasterUiDialogSelectMatchesComponent;
				break;
			default:
				break;
		}

		const height = dialogComponent?.dialogHeightMode === 'dynamic' ? undefined : '90%';

		if (tag && tag.toString() !== '' && dialogComponent) {
			this.dialog
				.open<unknown, TaggingDialogInput, TaggingDialogResult>(dialogComponent, {
					data: { selected: this.form.controls.filter.get(tag).value ?? [] },
					maxWidth: '90vw',
					maxHeight: '90vh',
					width: '90%',
					height
				})
				.afterClosed()
				.pipe(filter((result) => !!result))
				.subscribe((result) => this.form.controls.filter.get(tag).setValue([...result]));
		}
	}
}
