import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatProgressSpinner, ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { NgClass, NgStyle } from '@angular/common';

@Component({
	selector: 'dfl-button',
	templateUrl: './dfl-button.component.html',
	styleUrls: ['./dfl-button.component.scss'],
	standalone: true,
	imports: [MatProgressSpinner, NgClass, NgStyle],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DflButtonComponent implements OnChanges {
	@Input() loading = false;
	@Input() isSmallerSpacePage = false;
	@Input() disabled = false;
	@Input() color = '';
	@Input() backgroundColor = '';
	@Input() spinnerMode: ProgressSpinnerMode = 'indeterminate';
	@Input() spinnerDiameter = 20;
	@Input() type = 'button';
	@Input() buttonStyle: 'flat' | 'stroked' = 'flat';
	@Input() justifyStyle: 'space-between' | 'space-around' = 'space-around';

	constructor() {}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	ngOnChanges(changes: SimpleChanges): void {
		if (this.buttonStyle === 'stroked') {
			this.color = this.color === '' ? 'var(--button-primary-background)' : this.color;
			this.backgroundColor = this.backgroundColor === '' ? 'transparent' : this.backgroundColor;
		} else if (this.buttonStyle === 'flat') {
			this.color = this.color === '' ? 'var(--button-primary-typo)' : this.color;
			this.backgroundColor = this.backgroundColor === '' ? 'var(--button-primary-background)' : this.backgroundColor;
		}
	}
}
