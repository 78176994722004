export interface ClaudePromptImage {
	type: 'image';
	source: {
		type: string;
		data: string;
		media_type: string;
	};
}

export interface ClaudePromptText {
	type: 'text';
	text: string;
}

export interface ClaudeContentText {
	type: 'text';
	text: string;
}
export interface ClaudeContentThinking {
	type: 'thinking';
	thinking: string;
	signature: string;
}
export interface ClaudeContentRedactedThinking {
	type: 'redacted_thinking';
	data: string;
}
export type ClaudeContentItem = ClaudeContentText | ClaudeContentThinking | ClaudeContentRedactedThinking;

export interface ClaudePromptResonse {
	'role': string;
	'stop_sequence': string | null;
	'usage': {
		'output_tokens': number;
		'input_tokens': number;
	};
	'stop_reason': string;
	'model': string;
	'id': string;
	'type': string;
	'content': ClaudeContentItem[];
}
