import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { PagedApiResponse } from '@nx-bundesliga/models';
import { EMPTY, Observable, catchError, defaultIfEmpty, map } from 'rxjs';
import { CORE_ENVIRONMENT } from '../../bundesmaster-core.module';
import { BundesmasterEnvironment } from '../../bundesmaster-environment';
import { ArticleLanguages, StatusTypes } from '../../models/article.model';
import { GoalSlide, OpenSearchMatchStorySlides, OpenSearchStory, OpenSearchStorySlides, ProgressSlide, QuoteSlide, SLIDE_CTA_TYPES, SLIDE_TYPES, SlideCta, StoryTypes, TextSlide } from '../../models/story.model';

@Injectable({
	providedIn: 'root'
})
export class BundesmasterStoriesApiService {
	private readonly baseUrl: string;

	constructor(
		private readonly http: HttpClient,
		@Inject(CORE_ENVIRONMENT) private readonly environment: BundesmasterEnvironment,
		private readonly formBuilder: FormBuilder
	) {
		this.baseUrl = environment.apis.stories.url;
	}

	static matchStorySlidesSortedValidator = (storyType: StoryTypes): ValidatorFn => {
		return (group: FormGroup): ValidationErrors | null => {
			const calculatePlaytimeNumber = (slide: OpenSearchMatchStorySlides): number => {
				const minute = slide?.playtime?.minute || 0;
				const injuryTime = slide?.playtime?.injuryTime || 0;
				return parseInt(minute?.toString(10) + injuryTime?.toString(10)?.padStart(2, '0'));
			};
			if (storyType === 'match_story') {
				const slides: OpenSearchMatchStorySlides[] = group?.value ?? [];
				if (slides.length > 0) {
					const foundIndex = slides.findIndex((mSlide, index) => calculatePlaytimeNumber(mSlide) > calculatePlaytimeNumber(slides[index + (index < slides.length - 1 ? 1 : 0)]));
					if (foundIndex >= 0) {
						return { matchStorySortedSlides: true };
					}
					return null;
				}
				return null;
			}
			return null;
		};
	};

	public calculatePlaytimeNumber = (slide: OpenSearchMatchStorySlides): number => {
		const minute = slide?.playtime?.minute || 0;
		const injuryTime = slide?.playtime?.injuryTime || 0;
		return parseInt(minute?.toString(10) + injuryTime?.toString(10)?.padStart(2, '0'));
	};

	public findMatchStorySlidePositionByPlaytime = (oldSlides: OpenSearchMatchStorySlides[] = [], newSlide: OpenSearchMatchStorySlides): number => {
		if (oldSlides?.length === 0) {
			return 0;
		}
		function binarySearchForInsertion(arr: number[], target: number): number {
			let left = 0;
			let right = arr.length - 1;

			while (left <= right) {
				const mid = Math.floor((left + right) / 2);

				if (arr[mid] === target) {
					return mid;
				} else if (arr[mid] < target) {
					left = mid + 1;
				} else {
					right = mid - 1;
				}
			}
			return left;
		}
		function findNewIndex(arr: number[], target: number): number {
			const foundIndex = arr.findIndex((a, i) => target <= a && i < arr.length && arr[i + 1] > target);
			return foundIndex;
		}
		return binarySearchForInsertion(
			oldSlides.map((mSlide) => this.calculatePlaytimeNumber(mSlide)),
			this.calculatePlaytimeNumber(newSlide)
		);
		// return oldSlides.findIndex((mSlide) => this.calculatePlaytimeNumber(newSlide) < this.calculatePlaytimeNumber(mSlide));
	};

	public createSlideCtaFormGroup(cta?: SlideCta, ctaTypeOverride?: SLIDE_CTA_TYPES): FormGroup<any> {
		const target = (ctaTypeOverride || cta?.target?.toString()) as SLIDE_CTA_TYPES;
		switch (target) {
			case 'article': {
				return this.formBuilder.group({
					text: this.formBuilder.control<string>(cta?.text, [Validators.required]),
					target: this.formBuilder.control<string>(target, []),
					targetParams: this.formBuilder.group({
						articleId: this.formBuilder.control<string>(cta?.targetParams?.['articleId'] ?? '', [Validators.required])
					})
				});
				break;
			}
			case 'club': {
				return this.formBuilder.group({
					text: this.formBuilder.control<string>(cta?.text, [Validators.required]),
					target: this.formBuilder.control<string>(target, []),
					targetParams: this.formBuilder.group({
						competitionId: this.formBuilder.control<string>(cta?.targetParams?.['competitionId'] ?? '', [Validators.required]),
						seasonId: this.formBuilder.control<string>(cta?.targetParams?.['seasonId'] ?? '', [Validators.required]),
						clubId: this.formBuilder.control<string>(cta?.targetParams?.['clubId'] ?? '', [Validators.required])
					})
				});
				break;
			}
			case 'player': {
				return this.formBuilder.group({
					text: this.formBuilder.control<string>(cta?.text, [Validators.required]),
					target: this.formBuilder.control<string>(target, []),
					targetParams: this.formBuilder.group({
						competitionId: this.formBuilder.control<string>(cta?.targetParams?.['competitionId'] ?? '', [Validators.required]),
						seasonId: this.formBuilder.control<string>(cta?.targetParams?.['seasonId'] ?? '', [Validators.required]),
						clubId: this.formBuilder.control<string>(cta?.targetParams?.['clubId'] ?? '', [Validators.required]),
						personId: this.formBuilder.control<string>(cta?.targetParams?.['personId'] ?? '', [Validators.required])
					})
				});
				break;
			}
			case 'matchday': {
				return this.formBuilder.group({
					text: this.formBuilder.control<string>(cta?.text, [Validators.required]),
					target: this.formBuilder.control<string>(target, []),
					targetParams: this.formBuilder.group({
						competitionId: this.formBuilder.control<string>(cta?.targetParams?.['competitionId'] ?? '', [Validators.required]),
						seasonId: this.formBuilder.control<string>(cta?.targetParams?.['seasonId'] ?? '', [Validators.required]),
						matchdayId: this.formBuilder.control<string>(cta?.targetParams?.['matchdayId'] ?? '', [Validators.required])
					})
				});
				break;
			}
			case 'match': {
				return this.formBuilder.group({
					text: this.formBuilder.control<string>(cta?.text, [Validators.required]),
					target: this.formBuilder.control<string>(target, []),
					targetParams: this.formBuilder.group({
						competitionId: this.formBuilder.control<string>(cta?.targetParams?.['competitionId'] ?? '', [Validators.required]),
						seasonId: this.formBuilder.control<string>(cta?.targetParams?.['seasonId'] ?? '', [Validators.required]),
						matchdayId: this.formBuilder.control<string>(cta?.targetParams?.['matchdayId'] ?? '', [Validators.required]),
						matchId: this.formBuilder.control<string>(cta?.targetParams?.['matchId'] ?? '', [Validators.required]),
						tab: this.formBuilder.control<string>(cta?.targetParams?.['tab'] ?? 'liveticker', [Validators.required])
					})
				});
				break;
			}
			case 'monterosa': {
				return this.formBuilder.group({
					text: this.formBuilder.control<string>(cta?.text, [Validators.required]),
					target: this.formBuilder.control<string>(target, []),
					targetParams: this.formBuilder.group({
						eventId: this.formBuilder.control<string>(cta?.targetParams?.['eventId'] ?? '', [Validators.required]),
						projectId: this.formBuilder.control<string>(cta?.targetParams?.['projectId'] ?? this.environment?.monterosa?.projectId, [])
					})
				});
				break;
			}
			case 'games': {
				return this.formBuilder.group({
					text: this.formBuilder.control<string>(cta?.text, [Validators.required]),
					target: this.formBuilder.control<string>(target, []),
					targetParams: this.formBuilder.group({})
				});
				break;
			}
			case 'table': {
				return this.formBuilder.group({
					text: this.formBuilder.control<string>(cta?.text, [Validators.required]),
					target: this.formBuilder.control<string>(target, []),
					targetParams: this.formBuilder.group({
						competitionId: this.formBuilder.control<string>(cta?.targetParams?.['competitionId'] ?? '', [Validators.required]),
						seasonId: this.formBuilder.control<string>(cta?.targetParams?.['seasonId'] ?? '', [Validators.required])
					})
				});
				break;
			}
			case 'videos': {
				return this.formBuilder.group({
					text: this.formBuilder.control<string>(cta?.text, [Validators.required]),
					target: this.formBuilder.control<string>(target, []),
					targetParams: this.formBuilder.group({})
				});
				break;
			}
			default: {
				return this.formBuilder.group({
					text: this.formBuilder.control<string>(cta?.text ?? '', []),
					target: this.formBuilder.control<string>('', []),
					targetParams: this.formBuilder.group({})
				});
				break;
			}
		}
	}

	public createSlideFormGroup(slide: Partial<OpenSearchStorySlides>, storyType: StoryTypes): FormGroup<any> {
		const matchStoryPlaytime = {
			playtime: this.formBuilder.group({
				minute: this.formBuilder.control<number>((slide as OpenSearchMatchStorySlides)?.playtime?.minute ?? null, [Validators.required]),
				injuryTime: this.formBuilder.control<number>((slide as OpenSearchMatchStorySlides)?.playtime?.injuryTime ?? 0, [Validators.required])
			})
		};
		const matchStoryScore = {
			score: this.formBuilder.group({
				home: this.formBuilder.control<number>((slide as ProgressSlide | GoalSlide)?.score?.home ?? 0, [Validators.required]),
				away: this.formBuilder.control<number>((slide as ProgressSlide | GoalSlide)?.score?.away ?? 0, [Validators.required])
			})
		};
		const storyImage = (required = true) => ({
			image: this.formBuilder.group({
				url: this.formBuilder.control<string>((slide as TextSlide | QuoteSlide)?.image?.url, required ? [Validators.required] : []),
				copyright: this.formBuilder.control<string>((slide as TextSlide | QuoteSlide)?.image?.copyright, [])
			})
		});
		const commonStoryForms = {
			slideType: this.formBuilder.control<string>(slide.slideType, []),
			slideId: this.formBuilder.control<string>(slide.slideId, []),
			cta: this.createSlideCtaFormGroup(slide?.cta)
		};
		const matchStoryPerson = (item: any) =>
			this.formBuilder.group({
				dflDatalibraryClubId: this.formBuilder.control<string>(item?.dflDatalibraryClubId ?? '', [Validators.required]),
				dflDatalibraryObjectId: this.formBuilder.control<string>(item?.dflDatalibraryObjectId ?? '', [Validators.required]),
				name: this.formBuilder.control<string>(item?.name ?? '', [Validators.required]),
				imageUrl: this.formBuilder.control<string>(item?.imageUrl ?? '', [Validators.required])
			});
		const matchStoryLineupPerson = (item: any) =>
			this.formBuilder.group({
				dflDatalibraryClubId: this.formBuilder.control<string>(item?.dflDatalibraryClubId ?? '', [Validators.required]),
				dflDatalibraryObjectId: this.formBuilder.control<string>(item?.dflDatalibraryObjectId ?? '', [Validators.required]),
				name: this.formBuilder.control<string>(item?.name ?? '', [Validators.required]),
				shirtNumber: this.formBuilder.control<number>(item?.shirtNumber ?? '', [Validators.required]),
				imageUrl: this.formBuilder.control<string>(item?.imageUrl ?? '', [Validators.required])
			});

		if (slide.slideType === SLIDE_TYPES.GOAL) {
			return this.formBuilder.group({
				goalType: this.formBuilder.control<string>(slide?.goalType, [Validators.required]),
				scorer: matchStoryPerson(slide?.scorer),
				penalty: this.formBuilder.control<boolean>(slide?.penalty ?? false, [Validators.required]),
				xG: this.formBuilder.control<number>(slide?.xG ?? 0, [Validators.required]),
				distanceToGoal: this.formBuilder.control<number>(slide?.distanceToGoal ?? 0, [Validators.required]),
				shotSpeed: this.formBuilder.control<number>(slide?.shotSpeed ?? 0, [Validators.required]),
				...matchStoryScore,
				...commonStoryForms,
				...storyImage(false),
				...matchStoryPlaytime
			});
		}

		if (slide.slideType === SLIDE_TYPES.CARD) {
			return this.formBuilder.group({
				cardType: this.formBuilder.control<string>(slide?.cardType, [Validators.required]),
				person: matchStoryPerson(slide?.person),
				...commonStoryForms,
				...storyImage(false),
				...matchStoryPlaytime
			});
		}

		if (slide.slideType === SLIDE_TYPES.SUB) {
			return this.formBuilder.group({
				dflDatalibraryClubId: this.formBuilder.control<string>(slide?.dflDatalibraryClubId, [Validators.required]),
				in: matchStoryPerson(slide?.in),
				out: matchStoryPerson(slide?.out),
				...commonStoryForms,
				...storyImage(false),
				...matchStoryPlaytime
			});
		}

		if (slide.slideType === SLIDE_TYPES.PROGRESS) {
			return this.formBuilder.group({
				progressType: this.formBuilder.control<string>(slide?.progressType, [Validators.required]),
				...matchStoryScore,
				...commonStoryForms,
				...storyImage(false),
				...matchStoryPlaytime
			});
		}

		if (slide.slideType === SLIDE_TYPES.LINEUP) {
			return this.formBuilder.group({
				dflDatalibraryClubId: this.formBuilder.control<string>(slide?.dflDatalibraryClubId, [Validators.required]),
				persons: this.formBuilder.array(
					(slide.persons ?? Array(11).fill({})).map((person) => matchStoryLineupPerson(person)),
					[Validators.required]
				),
				...commonStoryForms,
				...storyImage(false),
				...matchStoryPlaytime
			});
		}

		if (slide.slideType === SLIDE_TYPES.QUOTE) {
			return this.formBuilder.group({
				author: this.formBuilder.control<string>(slide.author, [Validators.required]),
				quote: this.formBuilder.control<string>(slide.quote, [Validators.required, Validators.maxLength(130)]),
				...storyImage(true),
				...commonStoryForms,
				...(storyType === 'match_story' && matchStoryPlaytime)
			});
		}
		if (slide.slideType === SLIDE_TYPES.TEXT) {
			return this.formBuilder.group({
				headline: this.formBuilder.control<string>(slide.headline, [Validators.required]),
				text: this.formBuilder.control<string>(slide.text, [Validators.required]),
				...storyImage(true),
				...commonStoryForms,
				...(storyType === 'match_story' && matchStoryPlaytime)
			});
		}
		if (slide.slideType === SLIDE_TYPES.VIDEO) {
			return this.formBuilder.group({
				headline: this.formBuilder.control<string>(slide.headline, []),
				text: this.formBuilder.control<string>(slide.text, []),
				video: this.formBuilder.group({
					vid: this.formBuilder.control<string>(slide.video?.vid, []),
					duration: this.formBuilder.control<number>(slide.video?.duration, [])
				}),
				...commonStoryForms,
				...(storyType === 'match_story' && matchStoryPlaytime)
			});
		}
		if (slide.slideType === SLIDE_TYPES.MONTEROSA) {
			return this.formBuilder.group({
				headline: this.formBuilder.control<string>(slide.headline, []),
				text: this.formBuilder.control<string>(slide.text, []),
				monterosa: this.formBuilder.group({
					eventId: this.formBuilder.control<string>(slide.monterosa?.eventId, []),
					projectId: this.formBuilder.control<string>(slide.monterosa?.projectId, [])
				}),
				...commonStoryForms,
				...(storyType === 'match_story' && matchStoryPlaytime)
			});
		}
		return this.formBuilder.group({});
	}

	public createSlidesFormArray(slides: Partial<OpenSearchStorySlides>[] = [], storyType: StoryTypes = 'article_story'): FormArray<any> {
		const slidesFormArray: FormArray<any> = this.formBuilder.array([], [Validators.required]);
		slides?.forEach((slide) => {
			slidesFormArray.push(this.createSlideFormGroup(slide, storyType));
		});
		return slidesFormArray;
	}

	getStories(language: ArticleLanguages, page = 0, size = 24, sortBy = 'created_date', sortByOrder: 'desc' | 'asc' = 'desc', storyType?: StoryTypes, filters: Record<string, string | number> = {}, status?: string): Observable<PagedApiResponse<OpenSearchStory>> {
		const params = new HttpParams({ fromObject: { ...filters, page, size, sortBy, sortByOrder, ...(storyType && { storyType }), ...(status && { status }) } });
		return this.http.get<PagedApiResponse<OpenSearchStory>>(`${this.baseUrl}/stories`, { headers: { 'Accept-Language': language }, params }).pipe(
			catchError(() => EMPTY),
			defaultIfEmpty<PagedApiResponse<OpenSearchStory>, PagedApiResponse<OpenSearchStory>>({
				currentPageNumber: 0,
				elements: [],
				elementsOnPage: 0,
				elementsPerPage: 0,
				totalElementCount: 0,
				totalPageCount: 1
			})
		);
	}

	getStory<T = OpenSearchStory>(storyId: string): Observable<T> {
		return this.http.get<T>(`${this.baseUrl}/story/${storyId}`);
	}

	saveStory(storyId: string | null, story: Partial<OpenSearchStory>): Observable<boolean> {
		console.log('saveStory', story);
		return this.http.put<OpenSearchStory>(`${this.baseUrl}/story/${storyId}`, story).pipe(
			map(() => true),
			catchError(() => EMPTY),
			defaultIfEmpty<boolean, false>(false)
		);
	}

	createStory(story: Partial<OpenSearchStory>): Observable<{ storyId: string }> {
		console.log('createStory', story);
		return this.http.post<{ storyId: string }>(`${this.baseUrl}/story/`, story).pipe(catchError(() => EMPTY));
	}

	setStoryStatus(storyId: string, status: 'unpublished' | 'approved' | 'pending_approval'): Observable<boolean> {
		console.log('approveStory', storyId, status);
		return this.http.post<{ status: boolean }>(`${this.baseUrl}/story/${storyId}/approve`, { status }).pipe(
			map(() => true),
			catchError(() => EMPTY),
			defaultIfEmpty<boolean, false>(false)
		);
	}

	publishStory(storyId: string): Observable<boolean> {
		console.log('publishStory', storyId);
		return this.http.post<OpenSearchStory>(`${this.baseUrl}/story/${storyId}/publish`, null).pipe(
			map(() => true),
			catchError(() => EMPTY),
			defaultIfEmpty<boolean, false>(false)
		);
	}

	unpublishStory(storyId: string): Observable<boolean> {
		console.log('unpublishStory', storyId);
		return this.http.post<{ status: boolean }>(`${this.baseUrl}/story/${storyId}/unpublish`, null).pipe(
			map(() => true),
			catchError(() => EMPTY),
			defaultIfEmpty<boolean, false>(false)
		);
	}

	scheduleStory(storyId: string, action: 'schedule' | 'unschedule', date?: string) {
		console.log('scheduleStory', storyId, action, date);

		return this.http.post<{ status: StatusTypes; storyId: string }>(`${this.baseUrl}/story/${storyId}/schedule`, { date, action }).pipe(
			catchError(() => EMPTY),
			defaultIfEmpty<{ status: StatusTypes; storyId: string }, null>(null)
		);
	}

	deleteStory(storyId: string): Observable<boolean> {
		console.log('deleteStory', storyId);
		return this.http.delete<boolean>(`${this.baseUrl}/story/${storyId}`).pipe(
			map(() => true),
			catchError(() => EMPTY),
			defaultIfEmpty<boolean, false>(false)
		);
	}

	translateStory(storyId: string, languages: string[]): Observable<{ stories: { language: string; storyId: string }[] }> {
		console.log('translateStory', storyId, languages);
		return this.http.post<{ stories: { language: string; storyId: string }[] }>(`${this.baseUrl}/story/${storyId}/translate`, { languages });
	}

	getStoryPublishingHistory(storyId: string): Observable<OpenSearchStory[]> {
		console.log('getStoryPublishingHistory', storyId);
		return this.http.get<OpenSearchStory[]>(`${this.baseUrl}/story/${storyId}/history`).pipe(
			map(() => true),
			catchError(() => EMPTY),
			defaultIfEmpty<OpenSearchStory[], OpenSearchStory[]>([])
		);
	}
}
