import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { filter, map, switchMap } from 'rxjs';
import { UserAuthenticationService } from '../service/user-authentication.service';

@Component({
	selector: 'bundesmaster-authorizer-user-info',
	standalone: true,
	imports: [CommonModule, MatIconModule, MatChipsModule],
	templateUrl: './user-info.component.html',
	styleUrls: ['./user-info.component.scss']
})
export class BundesmasterAuthorizerUserInfoComponent {
	@Input() showName = true;

	readonly #authorizer = inject(UserAuthenticationService);
	readonly #http = inject(HttpClient);
	readonly #sanitizer = inject(DomSanitizer);

	readonly bundesmasterUser$ = this.#authorizer.user$.pipe(takeUntilDestroyed());
	readonly bundesmasterUserImage$ = this.#authorizer.isLoggedIn$.pipe(
		takeUntilDestroyed(),
		filter((loggedIn) => loggedIn),
		switchMap(() => {
			return this.#http.get('https://graph.microsoft.com/v1.0/me/photo/$value', { responseType: 'blob' }).pipe(map((blob) => this.#sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))));
		})
	);

	logout() {
		this.#authorizer.logout();
	}

	login() {
		this.#authorizer.login();
	}
}
