import { NgModule } from '@angular/core';
import { AssetServicePipe } from './asset-service/asset-service.pipe';
import { ResolveCountryNamePipe } from './country/resolve-country-name.pipe';
import { DateDiffPipe } from './date-diff/date-diff.pipe';
import { TimeStampDistancePipe } from './date-time/time-stamp-distance.pipe';
import { FetchProviderIdPipe } from './fetch-provider-id/fetch-provider-id.pipe';
import { FormatFileSizePipe } from './file-size/file-size.pipe';
import { FilterByValuePipe } from './filter-by-value/filter-by-value.pipe';
import { PartialListHintPipe } from './partial-list-hint/partial-list-hint.pipe';
import { PlayerImagePipe } from './player-image/player-image.pipe';
import { ResolveClubIdPipe } from './resolve-ids/resolve-club-id.pipe';
import { ResolveCompetitionIdPipe } from './resolve-ids/resolve-competition-id.pipe';
import { ResolveMatchIdPipe } from './resolve-ids/resolve-match-id.pipe';
import { ResolveMatchDayIdPipe } from './resolve-ids/resolve-matchday-id.pipe';
import { ResolvePersonByIdPipe, ResolvePersonIdPipe } from './resolve-ids/resolve-person-id.pipe';
import { ResolveSeasonIdPipe } from './resolve-ids/resolve-season-id.pipe';
import { TryResolveAnyIdPipe } from './resolve-ids/try-resolve-any-sts-id.pipe';
import { SplitPipe } from './split/split.pipe';
import { TimeAgoPipe } from './time-ago/time-ago.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';

@NgModule({
	declarations: [
		AssetServicePipe,
		TimeAgoPipe,
		TruncatePipe,
		PlayerImagePipe,
		FetchProviderIdPipe,
		SplitPipe,
		FilterByValuePipe,
		ResolveSeasonIdPipe,
		ResolveMatchDayIdPipe,
		ResolveMatchIdPipe,
		ResolveClubIdPipe,
		ResolvePersonIdPipe,
		ResolvePersonByIdPipe,
		ResolveCompetitionIdPipe,
		TryResolveAnyIdPipe,
		ResolveCountryNamePipe,
		TimeStampDistancePipe,
		FormatFileSizePipe,
		PartialListHintPipe,
		DateDiffPipe
	],
	exports: [
		AssetServicePipe,
		TimeAgoPipe,
		TruncatePipe,
		PlayerImagePipe,
		FetchProviderIdPipe,
		SplitPipe,
		FilterByValuePipe,
		ResolveSeasonIdPipe,
		ResolveMatchDayIdPipe,
		ResolveMatchIdPipe,
		ResolveClubIdPipe,
		ResolvePersonIdPipe,
		ResolvePersonByIdPipe,
		ResolveCompetitionIdPipe,
		TryResolveAnyIdPipe,
		ResolveCountryNamePipe,
		TimeStampDistancePipe,
		FormatFileSizePipe,
		PartialListHintPipe,
		DateDiffPipe
	],
	providers: [
		AssetServicePipe,
		TimeAgoPipe,
		TruncatePipe,
		PlayerImagePipe,
		FetchProviderIdPipe,
		SplitPipe,
		FilterByValuePipe,
		ResolveSeasonIdPipe,
		ResolveMatchDayIdPipe,
		ResolveMatchIdPipe,
		ResolveClubIdPipe,
		ResolvePersonIdPipe,
		ResolveCompetitionIdPipe,
		TryResolveAnyIdPipe,
		ResolveCountryNamePipe,
		TimeStampDistancePipe,
		FormatFileSizePipe,
		PartialListHintPipe,
		DateDiffPipe
	]
})
export class PipesModule {}
