import { TagsTypes } from '@nx-bundesliga/bundesmaster/core';
import { LlmImageTagEnum } from '@nx-bundesliga/bundesmaster/shared';

export interface SearchFilter {
	name: string;
	type: string;
	id?: string;
}

export interface SearchQuery {
	search?: string;
	search_semantic?: string;
	dateRange?: {
		start?: Date | null;
		end?: Date | null;
	};
	filter?: { [key in TagsTypes]?: string[] } & { readonly motif?: readonly string[] } & { readonly llmTags?: readonly LlmImageTagEnum[] };
	selectFilter?: { [key: string]: string };
}
