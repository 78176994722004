import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
	selector: 'dfl-loader',
	templateUrl: './dfl-loader.component.html',
	styleUrls: ['./dfl-loader.component.scss'],
	standalone: true,
	imports: [NgClass]
})
export class DflLoaderComponent {
	@Input() loading = false;
	@Input() absolute = false;
	@Input() surrounding: 'small' | 'medium' | 'large' = 'large';

	constructor() {}
}
