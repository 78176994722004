@if (playtime && playtime.minute >= 0) {
	<div class="wrapper" [ngClass]="{ 'large': layout === 'large', 'is-paused': !live }">
		<div class="badge-wrapper">
			@switch (true) {
				@case (live !== true && layout !== 'xsmall' && matchStatus === 'HALF') {
					<div class="badge">{{ 'LIVETICKER.end_firstHalf' | translate }}</div>
				}
				@case (live !== true && layout === 'xsmall' && matchStatus === 'HALF') {
					<div class="badge">{{ 'LIVETICKER.end_firstHalf_small' | translate }}</div>
				}
				@case (live !== true && layout !== 'xsmall' && matchStatus === 'HALF_EXTRA') {
					<div class="badge">{{ 'LIVETICKER.end_firstHalfExtra' | translate }}</div>
				}
				@case (live !== true && layout === 'xsmall' && matchStatus === 'HALF_EXTRA') {
					<div class="badge">{{ 'LIVETICKER.end_firstHalfExtra_small' | translate }}</div>
				}
				@case (live === true && layout !== 'xsmall' && (matchStatus === 'PRE_PENALTY' || matchStatus === 'PENALTY')) {
					<div class="badge">{{ 'LIVETICKER.start_penalty' | translate }}</div>
				}
				@case (live === true && layout === 'xsmall' && (matchStatus === 'PRE_PENALTY' || matchStatus === 'PENALTY')) {
					<div class="badge">{{ 'LIVETICKER.start_penalty_small' | translate }}</div>
				}
				@case (live === true && matchStatus !== 'PRE_PENALTY' && matchStatus !== 'PENALTY') {
					<div class="badge">LIVE</div>
				}
			}
			@if (live && layout === 'xsmall') {
				<div class="animation">
					<div class="block" id="block1"></div>
					<div class="block" id="block2"></div>
					<div class="block" id="block3"></div>
					<div class="block" id="block4"></div>
					<div class="block" id="block5"></div>
					<div class="block" id="block6"></div>
				</div>
			}
		</div>
		@if (live && layout !== 'xsmall' && playtime && playtime.minute >= 0 && matchStatus !== 'PRE_PENALTY' && matchStatus !== 'PENALTY') {
			<span class="playtime">{{ playtime.minute }}'</span>
			@if (layout === 'large' && playtime.injuryTime > 0) {
				<span class="playtime injury">+{{ playtime.injuryTime }}</span>
			}
		}
	</div>
	@if (live && (layout === 'large' || layout === 'regular')) {
		<!-- Knightrider Animation Template -->
		<div class="animation" [ngClass]="{ 'large': layout === 'large' }">
			<div class="block" id="block1"></div>
			<div class="block" id="block2"></div>
			<div class="block" id="block3"></div>
			<div class="block" id="block4"></div>
			<div class="block" id="block5"></div>
			<div class="block" id="block6"></div>
		</div>
	}
}
