import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BUNDESLIGA_COMPETITIONS, BUNDESLIGA_SEASONS, BundesmasterClub, BundesmasterClubApiService, BundesmasterEnvironment, BundesmasterPerson, BundesmasterPersonApiService, CORE_ENVIRONMENT, selectArticleLanguage } from '@nx-bundesliga/bundesmaster/core';
import { SlugifyPipe } from '@nx-bundesliga/commons';
import { combineLatest, lastValueFrom, map, take } from 'rxjs';
import * as tinymce from 'tinymce';

@Injectable({
	providedIn: 'root'
})
export class MatTinyMceAutoCompletionService {
	private autoCompletionThrottleToken = null;

	constructor(private readonly personService: BundesmasterPersonApiService, private readonly clubService: BundesmasterClubApiService, private readonly store: Store, private readonly slugify: SlugifyPipe, @Inject(CORE_ENVIRONMENT) private readonly environment: BundesmasterEnvironment) {}

	public async getAutoCompletions(input: string): Promise<tinymce.Ui.InlineContent.AutocompleterContents[]> {
		clearTimeout(this.autoCompletionThrottleToken);
		return new Promise((resolve) => (this.autoCompletionThrottleToken = setTimeout(async () => resolve(await this.getThrottledAutoCompletions(input)), 250)));
	}

	private async getThrottledAutoCompletions(query: string): Promise<tinymce.Ui.InlineContent.AutocompleterContents[]> {
		return lastValueFrom(
			combineLatest([this.personService.getPersons({ query }), this.clubService.getClubsCollection({ name: query }), this.clubService.getClubsCollection(), this.store.select(selectArticleLanguage).pipe(take(1))]).pipe(
				map(([persons, clubs, allClubs, language]) => [...clubs.items.map((club) => this.buildClubCompletionItem(club, language)), ...persons.items.map((person) => this.buildPersonCompletionItem(person, language, allClubs.itemMap))])
			)
		);
	}

	private buildCompletionItem({ iconUrl, insertText, subTitle, title }: Readonly<{ iconUrl: string; title: string; subTitle: string; insertText: string }>): tinymce.Ui.InlineContent.AutocompleterContents {
		return {
			type: 'cardmenuitem',
			value: insertText,
			label: title,
			items: [
				{
					type: 'cardcontainer',
					direction: 'horizontal',
					valign: 'middle',
					items: [
						{
							type: 'cardimage',
							src: iconUrl,
							alt: '',
							classes: ['bundesmaster-tinymce-suggestion-image']
						},
						{
							type: 'cardcontainer',
							direction: 'vertical',
							valign: 'middle',
							items: [
								{
									type: 'cardtext',
									text: title,
									classes: ['text-nowrap', 'bundesmaster-tinymce-suggestion-title']
								},
								{
									type: 'cardtext',
									text: subTitle,
									classes: ['text-nowrap', 'bundesmaster-tinymce-suggestion-subtitle']
								}
							]
						}
					]
				}
			]
		};
	}

	private getCompetitionForClub({ seasons }: Pick<BundesmasterClub, 'seasons'> = { seasons: {} }): string {
		return (seasons[BUNDESLIGA_SEASONS.CURRENT] ?? []).some((competitionId) => competitionId === BUNDESLIGA_COMPETITIONS.BUNDESLIGA2) ? '2bundesliga' : 'bundesliga';
	}

	private buildClubCompletionItem({ clubId, threeLetterCode, logoUrl, displayName, seasons }: BundesmasterClub, language: string): tinymce.Ui.InlineContent.AutocompleterContents {
		const clubUrlParts: Record<string, string> = {
			'en': 'clubs',
			'es': 'clubes',
			'ja': 'clubs',
			'jp': 'clubs',
			'de': 'clubs',
			'fr': 'clubs'
		};

		const clubPart = clubUrlParts[language] ?? clubUrlParts['en'];

		const competition = this.getCompetitionForClub({ seasons });

		const slugifyInput = displayName.replace(/ä/gi, 'ae').replace(/ö/gi, 'oe').replace(/ü/gi, 'ue').replace(/ß/gi, 'ss');

		const insertText = `<a data-dfl-club-id="${clubId}" href="${this.environment.domain}/${language}/${competition}/${clubPart}/${this.slugify.transform(slugifyInput)}">${displayName}</a>`;

		return this.buildCompletionItem({
			title: displayName,
			subTitle: `${threeLetterCode} | ${clubId}`,
			iconUrl: logoUrl ?? '/assets/clublogo/default_clublogo.svg',
			insertText
		});
	}

	private buildPersonCompletionItem(person: BundesmasterPerson, language: string, clubs: ReadonlyMap<string, BundesmasterClub>): tinymce.Ui.InlineContent.AutocompleterContents {
		const playerUrlParts: Record<string, string> = {
			'en': 'player',
			'es': 'jugador',
			'ja': 'player',
			'jp': 'player',
			'fr': 'joueur',
			'de': 'spieler'
		};

		const playerPart = playerUrlParts[language] ?? playerUrlParts['en'];

		const { personId, aliasName, displayName, firstActiveYear, lastActiveYear } = person;

		const title = aliasName ?? displayName;
		const competition = this.getCompetitionForClub(clubs.get(person.mostRecentClub?.clubId ?? ''));

		const anchorElement = `<a data-dfl-object-id="${personId}" href="${this.environment.domain}/${language}/${competition}/${playerPart}/${personId}">${title}</a>`;

		const details: string[] = [];

		details.push(`${firstActiveYear} - ${lastActiveYear}`);

		if (person.allClubs.length > 0) {
			const displayLimit = 3;
			let clubs = person.allClubs
				.slice(0, displayLimit)
				.map(({ threeLetterCode }) => threeLetterCode)
				.join(', ');
			const notDisplayedCount = person.allClubs.length - displayLimit;
			clubs = notDisplayedCount > 0 ? `${clubs} (+${notDisplayedCount})` : clubs;
			details.push(clubs);
		}

		details.push(personId);

		return this.buildCompletionItem({
			iconUrl: this.personService.buildCircleImageUrl(person),
			insertText: anchorElement,
			subTitle: details.join(' | '),
			title
		});
	}
}
