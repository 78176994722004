@if (layout === 'article'){
<dfl-loader-article [layout]="layout" [webview]="webview"></dfl-loader-article>
} @if (layout === 'teaser') {
<div class="timeline-item" [ngClass]="{'item--xl': size === 'large'}">
	<header></header>
	<div class="body animated-background">
		<div class="background-mask headline-first-top"></div>
		<div class="background-mask headline-first-left"></div>
		<div class="background-mask headline-first-right"></div>
		<div class="background-mask headline-second-top"></div>
		<div class="background-mask headline-second-left"></div>
		<div class="background-mask headline-second-right"></div>
		<div class="background-mask abstract-first-top"></div>
		<div class="background-mask abstract-first-left"></div>
		<div class="background-mask abstract-first-right"></div>
		<div class="background-mask abstract-second-top"></div>
		<div class="background-mask abstract-second-left"></div>
		<div class="background-mask abstract-second-right"></div>
		<div class="background-mask abstract-third-top"></div>
		<div class="background-mask abstract-third-left"></div>
		<div class="background-mask abstract-third-right"></div>
		<div class="background-mask time-ago-top"></div>
		<div class="background-mask time-ago-left"></div>
		<div class="background-mask time-ago-right"></div>
		<div class="background-mask spacer-bottom"></div>
	</div>
</div>
}
