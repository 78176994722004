
    export const buildVars = {
      version: '1.8.6',
      build: '10717',
      angularVersion: '18.2.8',
	  nxVersion: '19.8.4',
      commitId: '5490c3a1f',
      commitTime: '2025-04-03 10:58:46 +0000',
	  branch: 'bundesmastersync_03_04_2025'
    };
    