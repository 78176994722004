
    export const buildVars = {
      version: '1.8.0',
      build: '9431',
      angularVersion: '18.2.8',
	  nxVersion: '19.8.4',
      commitId: '069d5d748',
      commitTime: '2024-11-15 18:23:20 +0100',
	  branch: 'matchreport-refinements'
    };
    