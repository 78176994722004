<mat-chip-listbox>
	<ng-container *ngIf="(bundesmasterUser$ | async) as user">
		<mat-chip>
			<img matChipAvatar *ngIf="bundesmasterUserImage$ | async as image" [src]="image" [alt]="user.name" class="w-100" />
			<span *ngIf="showName">{{ user.name }}</span>
		</mat-chip>

		<mat-chip (click)="logout()">
			<mat-icon matChipAvatar>logout</mat-icon>
			<span>Logout</span>
		</mat-chip>
	</ng-container>

	<mat-chip *ngIf="(bundesmasterUser$ | async) === null" (click)="login()">
		<mat-icon matChipAvatar>login</mat-icon>
		<span>Login</span>
	</mat-chip>
</mat-chip-listbox>
