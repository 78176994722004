
    export const buildVars = {
      version: '1.8.6',
      build: '10317',
      angularVersion: '18.2.8',
	  nxVersion: '19.8.4',
      commitId: '57568048d',
      commitTime: '2025-02-14 11:04:09 +0100',
	  branch: 'CON-poses-story-NEVER-MERGE-TO-MAIN'
    };
    